import React, { useEffect } from 'react'
import { Form } from 'antd'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import client from '@cms/core/graphql/client'
import { useState } from 'react'
import SelectSearch from '@cms/core/components/SelectSearch'
import { getCurrentType } from '../utils'
import { useSegment } from '../context'

const multiSelectOperator = ['$in', '$nin']

const FieldValue = ({ field, type, name }) => {
    const { eventId } = useParams()
    const form = Form.useFormInstance()
    const currentFieldName = ['sections', ...name, 'filters', field.name]
    const [fieldOptions, setFieldOptions] = useState(null)
    const [prevConditionalField, setPrevConditionalField] = useState(
        form.getFieldValue([...currentFieldName, 'field'])
    )
    const [prevOperator, setPrevOperator] = useState(
        form.getFieldValue([...currentFieldName, 'type'])
    )
    const { filters } = useSegment()

    const conditionalField = Form.useWatch([...currentFieldName, 'field'])
    const operator = Form.useWatch([...currentFieldName, 'type'])
    const value = Form.useWatch([...currentFieldName, 'value'])

    const _type = getCurrentType(conditionalField, type, filters)

    useEffect(() => {
        if (conditionalField && operator) {
            if (_type === 'boolean') {
                return
            }
            if (conditionalField !== prevConditionalField) {
                form.setFieldValue([...currentFieldName, 'value'], undefined)
                setPrevConditionalField(conditionalField)
            }
            fetchData()
        }
    }, [conditionalField, operator])

    useEffect(() => {
        if (value && operator) {
            if (_type === 'boolean') {
                return
            }
            if (operator !== prevOperator) {
                if (multiSelectOperator.includes(operator)) {
                    form.setFieldValue(
                        [...currentFieldName, 'value'],
                        Array.isArray(value) ? value : [value]
                    )
                } else {
                    form.setFieldValue(
                        [...currentFieldName, 'value'],
                        Array.isArray(value) ? value[0] : value
                    )
                }
                setPrevOperator(operator)
            }
        }
    }, [operator, value])

    const fetchData = async () => {
        const current = form.getFieldValue([...currentFieldName, 'field'])

        if (!current) {
            return
        }

        const infoSection = filters[type]
        const fieldType = current
        const _options = infoSection.fields[fieldType].options

        try {
            const resolvedOptions =
                typeof _options === 'function'
                    ? await _options(client, eventId)
                    : _options

            setFieldOptions(resolvedOptions)
        } catch (error) {
            console.error('Error fetching field options:', error)
        }
    }

    if (_type === 'boolean') {
        return null
    }

    if (fieldOptions === null) {
        return <span>Selecione operador</span>
    }

    return (
        <Form.Item noStyle>
            <Form.Item
                name={[field.name, 'value']}
                rules={[
                    {
                        required: true,
                        message: 'Campo obrigatório'
                    }
                ]}
            >
                <SelectSearch
                    placeholder="Buscar"
                    optionFilterProp="children"
                    maxTagCount={2}
                    options={fieldOptions}
                    mode={
                        multiSelectOperator.includes(operator)
                            ? 'multiple'
                            : null
                    }
                />
            </Form.Item>
        </Form.Item>
    )
}

export default FieldValue

