import { gql } from '@apollo/client'
import { UserData } from '../fragments'

export const GET_TEAM_LIST = gql`
    query GetTeams($first: Int, $afterCursor: Cursor) {
        users(
            first: $first
            after: $afterCursor
            filter: {
                role: { in: ["moderator", "editor", "admin", "superadmin"] }
            }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...UserData
                }
            }
        }
    }

    ${UserData}
`

export const GET_TEAM_LIST_ORDERABLE = gql`
    query GetTeams(
        $first: Int
        $afterCursor: Cursor
        $search: String
        $orderBy: UserOrderBy
    ) {
        users(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: {
                role: { in: ["moderator", "editor", "admin", "superadmin"] }
            }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...UserData
                }
            }
        }
    }

    ${UserData}
`

export const GET_USERS_BY_ROLE = gql`
    query GetUsersByRole(
        $first: Int
        $afterCursor: Cursor
        $search: String
        $orderBy: UserOrderBy
        $role: Filter!
        $name: Filter
        $email: Filter
    ) {
        users(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: { role: $role, name: $name, email: $email }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...UserData
                }
            }
        }
    }

    ${UserData}
`

export const GET_USERS_BANNED = gql`
    query GetUsersBanned(
        $first: Int
        $afterCursor: Cursor
        $orderBy: UserOrderBy
        $name: Filter
        $email: Filter
    ) {
        users(
            first: $first
            after: $afterCursor
            filter: { banned: true, name: $name, email: $email }
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...UserData
                }
            }
        }
    }

    ${UserData}
`

export const GET_USER = gql`
    query GetUser($id: ObjectID!) {
        user(id: $id) {
            ...UserData
        }
    }

    ${UserData}
`

export const GET_USER_BLOCKED = gql`
    query GetUserBlocked($id: ObjectID!) {
        user(id: $id) {
            blockDate
        }
    }
`
