import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_EVENT = gql`
    mutation CreateEvent(
        $type: EventType
        $hostType: EventHostType
        $name: String
        $isPublished: Boolean
        $description: String
        $address: String
        $startDate: DateTime
        $endDate: DateTime
        $featured: Boolean
        $slogan: String
        $highlightSlogan: String
        $theme: ThemeInputs
        $themeV2: JSON
    ) {
        createEvent(
            input: {
                type: $type
                hostType: $hostType
                name: $name
                isPublished: $isPublished
                description: $description
                address: $address
                startDate: $startDate
                endDate: $endDate
                featured: $featured
                slogan: $slogan
                highlightSlogan: $highlightSlogan
                theme: $theme
                themeV2: $themeV2
            }
        ) {
            ...EventData
        }
    }

    ${fragments.EventData}
`

export const UPDATE_CHECKOUT_CONFIG = gql`
    mutation UpdateCheckoutConfig(
        $id: ObjectID!
        $checkoutConfig: CheckoutConfigInput
    ) {
        updateCheckoutConfig(id: $id, input: $checkoutConfig) {
            ...CheckoutConfigData
        }
    }

    ${fragments.CheckoutConfigData}
`

export const UPDATE_EVENT = gql`
    mutation UpdateEvent(
        $id: ObjectID!
        $type: EventType
        $hostType: EventHostType
        $name: String
        $isPublished: Boolean
        $description: String
        $addressName: String
        $address: String
        $startDate: DateTime
        $endDate: DateTime
        $logo: MediaInput
        $backgroundImageMobile: MediaInput
        $backgroundImageDesktop: MediaInput
        $backgroundImageHome: MediaInput
        $scheduleImage: MediaInput
        $scheduleImageMobile: MediaInput
        $featured: Boolean
        $slogan: String
        $highlightSlogan: String
        $site: ObjectID
        $viralCampaign: ObjectID
        $domain: DomainInputs
        $streamingDomain: DomainInputs
        $checkoutDomain: DomainInputs
        $certificate: CertificateInputs
        $scripts: ScriptsInputs
        $terms: TermsInputs
        $theme: ThemeInputs
        $themeV2: JSON
        $hashtags: [String]
        $chatConfig: ChatConfigInputs
        $players: [PlayerInput]
        $eventInfos: String
        $integrations: [IntegrationsInput]
        $showNumberOfViewers: Boolean
        $showReactions: Boolean
        $showNumberOfRegistrations: Boolean
        $showNumberOfSpeakers: Boolean
        $showDates: Boolean
        $showLiveSponsor: Boolean
        $showLiveChat: Boolean
        $showSocialAuth: Boolean
        $allowStreamingRegistration: Boolean
        $translation: TranslateInput
        $showSpeaperkSocialInfo: Boolean
        $tag: String
        $menus: MenusInput
        $helpMail: String
        $liveTagText: String
        $home: HomeInput
        $checkoutConfig: CheckoutConfigInput
        $footerConfig: FooterConfigInput
        $mobileConfig: EventMobileConfigInput
        $eventMapImage: MediaInput
    ) {
        updateEvent(
            id: $id
            input: {
                type: $type
                hostType: $hostType
                name: $name
                isPublished: $isPublished
                description: $description
                addressName: $addressName
                address: $address
                startDate: $startDate
                endDate: $endDate
                theme: $theme
                themeV2: $themeV2
                logo: $logo
                backgroundImageMobile: $backgroundImageMobile
                backgroundImageDesktop: $backgroundImageDesktop
                backgroundImageHome: $backgroundImageHome
                scheduleImage: $scheduleImage
                scheduleImageMobile: $scheduleImageMobile
                featured: $featured
                slogan: $slogan
                highlightSlogan: $highlightSlogan
                site: $site
                viralCampaign: $viralCampaign
                domain: $domain
                streamingDomain: $streamingDomain
                checkoutDomain: $checkoutDomain
                certificate: $certificate
                scripts: $scripts
                terms: $terms
                hashtags: $hashtags
                chatConfig: $chatConfig
                players: $players
                eventInfos: $eventInfos
                checkoutConfig: $checkoutConfig
                integrations: $integrations
                showReactions: $showReactions
                showNumberOfViewers: $showNumberOfViewers
                showNumberOfRegistrations: $showNumberOfRegistrations
                showNumberOfSpeakers: $showNumberOfSpeakers
                showDates: $showDates
                showLiveSponsor: $showLiveSponsor
                showLiveChat: $showLiveChat
                showSocialAuth: $showSocialAuth
                allowStreamingRegistration: $allowStreamingRegistration
                translation: $translation
                showSpeaperkSocialInfo: $showSpeaperkSocialInfo
                tag: $tag
                menus: $menus
                helpMail: $helpMail
                liveTagText: $liveTagText
                home: $home
                footerConfig: $footerConfig
                mobileConfig: $mobileConfig
                eventMapImage: $eventMapImage
            }
        ) {
            ...EventData
        }
    }

    ${fragments.EventData}
`

export const DELETE_EVENT = gql`
    mutation DeleteEvent($id: ObjectID!) {
        deleteEvent(id: $id) {
            id
            success
        }
    }
`

