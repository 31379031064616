import React from 'react'
import {
    message,
    Form,
    Input,
    InputNumber,
    Divider,
    Row,
    Col,
    Select
} from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { addToList } from '@cms/core/graphql/utils'
import {
    CREATE_CUSTOMER,
    UPDATE_CUSTOMER
} from '@cms/core/graphql/mutations/customer'
import { GET_BILLING_PLANS_LIST_ORDENABLE } from '@cms/admin/graphql/queries/billingPlan'
import { GET_CUSTOMER_EVENT } from '@cms/events/graphql/queries/customer'

const Modules = [
    {
        value: 'TICKET',
        label: 'Tickets'
    },
    {
        value: 'EMAILABLE',
        label: 'Emailable'
    }
]

const CustomersModal = ({ ...props }) => {
    const client = useApolloClient()

    const queryBillingPlan = useQuery(GET_BILLING_PLANS_LIST_ORDENABLE, {
        variables: { first: 99999, orderBy: { name: 'ASC' } }
    })

    const [{ form, isEdit, getError }, modalProps] = useCrudForm(
        {
            relateds: [],
            getData: async (id) => {
                if (!id) return

                const { data } = await client.query({
                    query: GET_CUSTOMER_EVENT,
                    variables: { id }
                })

                return {
                    ...data.customer,
                    billingPlan: data.customer?.billingPlan?.id
                }
            },
            createData: async (formData) => {
                await client.mutate({
                    mutation: CREATE_CUSTOMER,
                    update: addToList({ list: 'customers', Type: 'Customer' }),
                    variables: { ...formData }
                })

                message.success('Cliente cadastrado com sucesso!')
            },
            updateData: async (id, formData) => {
                const { errors } = await client.mutate({
                    mutation: UPDATE_CUSTOMER,
                    variables: { id, ...formData }
                })

                if (errors) throw errors

                message.success('Cliente atualizado com sucesso!')
            }
        },
        []
    )

    return (
        <CrudModal
            title={isEdit ? 'Editar Cliente' : 'Novo Cliente'}
            {...modalProps}
            {...props}
            width={800}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Email" name="email" {...getError('email')}>
                    <Input type="email" />
                </Form.Item>

                <Form.Item
                    label="CNPJ (apenas números)"
                    name="cnpj"
                    {...getError('cnpj')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="DB"
                    name="db"
                    rules={[{ required: true, message: 'DB é obrigatório!' }]}
                    {...getError('db')}
                >
                    <Input disabled={isEdit} suffix=".app.td" />
                </Form.Item>

                <Divider orientation="left">Permissões</Divider>

                <Form.Item
                    label="Plano"
                    name="billingPlan"
                    rules={[
                        { required: true, message: 'Plano é obrigatório!' }
                    ]}
                    {...getError('billingPlan')}
                >
                    <Select>
                        {queryBillingPlan?.data?.billingPlans?.edges?.map(
                            ({ node }) => (
                                <Select.Option
                                    key={node.id}
                                    value={node.id}
                                    title={node.name}
                                >
                                    {node.name}
                                </Select.Option>
                            )
                        )}
                    </Select>
                </Form.Item>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Limite de Eventos (-1 para infinito)"
                            name={['permissions', 'events', 'amount']}
                            {...getError('firstName')}
                        >
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Limite do Time (-1 para infinito)"
                            name={['permissions', 'team', 'amount']}
                            {...getError('firstName')}
                        >
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left">Módulos</Divider>

                <Form.Item name="modules">
                    <Select mode="multiple">
                        {Modules.map((module) => (
                            <Select.Option
                                key={module.value}
                                value={module.value}
                                title={module.label}
                            >
                                {module.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => {
                        return (
                            prevValues.modules !== currentValues.modules ||
                            prevValues.paymentGateway !==
                                currentValues.paymentGateway
                        )
                    }}
                >
                    {({ getFieldValue }) => {
                        const modules = getFieldValue('modules')
                        const paymentGateway = getFieldValue('paymentGateway')

                        if (!modules || !modules.includes('TICKET')) {
                            return null
                        }

                        return (
                            <>
                                <Divider orientation="left">
                                    Modulo - Ticket
                                </Divider>

                                <Form.Item
                                    label="Gateway de Pagamento"
                                    name="paymentGateway"
                                    {...getError('paymentGateway')}
                                >
                                    <Select>
                                        <Select.Option value="PAGARME">
                                            Pagar.me
                                        </Select.Option>
                                        <Select.Option value="STRIPE">
                                            Stripe
                                        </Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Porcentagem da Retenção"
                                    name="splitPercentage"
                                    {...getError('splitPercentage')}
                                >
                                    <InputNumber min={1} max={100} />
                                </Form.Item>

                                <Form.Item
                                    style={{
                                        display:
                                            paymentGateway !== 'PAGARME'
                                                ? 'none'
                                                : ''
                                    }}
                                    label="Pagarme - ID do Recebedor"
                                    name={['splitRecipient', 'pagarme']}
                                    {...getError('splitRecipient.pagarme')}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    style={{
                                        display:
                                            paymentGateway !== 'STRIPE'
                                                ? 'none'
                                                : ''
                                    }}
                                    label="Stripe - Connect Account ID"
                                    name={['splitRecipient', 'stripe']}
                                    {...getError('splitRecipient.stripe')}
                                >
                                    <Input />
                                </Form.Item>
                            </>
                        )
                    }}
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => {
                        return prevValues.modules !== currentValues.modules
                    }}
                >
                    {({ getFieldValue }) => {
                        const modules = getFieldValue('modules')

                        if (!modules || !modules.includes('EMAILABLE')) {
                            return null
                        }

                        return (
                            <>
                                <Divider orientation="left">
                                    Modulo - Emailable
                                </Divider>

                                <Form.Item
                                    label="API Key Emailable"
                                    name="emailableApiKey"
                                    {...getError('emailableApiKey')}
                                >
                                    <Input />
                                </Form.Item>
                            </>
                        )
                    }}
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default CustomersModal
