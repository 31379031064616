import React, { useEffect } from 'react'
import { Col, Row, Form } from 'antd'

import Content from '@cms/core/components/Content'

import { List, Section } from './components/Sections'
import Title from 'antd/es/typography/Title'
import { useThemeBuilderContext } from '../../context'
import { useCallback } from 'react'
import Loader from '@cms/core/components/Loader'
import { useParams } from 'react-router-dom'

const ThemeBuilderConfigMenu = () => {
    const { eventId } = useParams()

    const [form] = Form.useForm()
    const { menus, setMenus, loading } = useThemeBuilderContext()

    useEffect(() => {
        if (!loading) {
            if (menus) {
                form.setFieldsValue({ menus })
            }
        }
    }, [menus])

    const handleSubmit = useCallback(
        (e) => {
            const values = form.getFieldsValue()

            setMenus({
                ...values
            })
        },
        [setMenus]
    )

    if (loading) {
        return <Loader />
    }

    const DEFAULT_MENUS = [
        {
            name: 'main',
            label: 'Principal',
            sectionDefault: eventId && {
                name: 'Home',
                editable: false
            }
        },
        {
            name: 'user',
            label: 'Menu do usuário',
            sectionDefault: eventId && {
                name: 'Perfil do usuário',
                editable: false
            }
        }
    ]

    return (
        <>
            <Content>
                <Form layout="vertical" form={form} onFinish={handleSubmit}>
                    <Row justify="space-between">
                        {DEFAULT_MENUS.map((menu) => (
                            <Col md={11} key={menu.name}>
                                <Title level={3}>{menu.label}</Title>
                                {menu?.sectionDefault && (
                                    <>
                                        <Section
                                            name={menu.sectionDefault?.name}
                                            form={form}
                                            editable={
                                                menu.sectionDefault?.editable
                                            }
                                        />
                                        <br />
                                    </>
                                )}
                                <List form={form} menu={menu.name} />
                            </Col>
                        ))}
                    </Row>
                </Form>
            </Content>
        </>
    )
}

export default ThemeBuilderConfigMenu

