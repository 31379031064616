import { gql } from '@apollo/client'
import { UserWatchProductData } from '../fragments'

export const GET_USER_WATCH_PRODUCTS_LIST_ORDENABLE = gql`
    query GetUserWatchProducts(
        $first: Int
        $afterCursor: Cursor
        $orderBy: UserWatchProductOrderBy
        $search: String
        $user: ObjectID
    ) {
        userWatchProducts(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: { user: $user }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...UserWatchProductData
                }
            }
        }
    }

    ${UserWatchProductData}
`

export const GET_USER_WATCH_PRODUCT = gql`
    query GetUserWatchProduct($id: ObjectID!) {
        userWatchProduct(id: $id) {
            ...UserWatchProductData
        }
    }

    ${UserWatchProductData}
`

