import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { currencies, CurrencyType } from './currencies'

import { Input } from 'antd'

interface CurrencyInputProps {
    currency?: CurrencyType
    onChange: (value: string | number) => void
    value: string | number
}

export const formatCurrency = (
    value: string | number,
    currency: CurrencyType
) => {
    const { locale, intlConfig } =
        currencies.find((c) => c.symbol === currency) || currencies[0]
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        ...intlConfig
    })

    return formatter.format(Number(value || 0) / 100)
}

export const CurrencyInput = ({
    currency,
    onChange,
    value,
    ...props
}: CurrencyInputProps) => {
    const inputEl = useRef(null)

    const formatValue = useCallback(
        (value: string | number) => {
            return formatCurrency(value, currency)
        },
        [currency]
    )

    return (
        <Input
            ref={(_) => (inputEl.current = _?.input)}
            value={formatValue(value)}
            placeholder={formatCurrency(0, currency)}
            onChange={() => {
                const { value, selectionEnd } = inputEl.current
                const rightCharsCount = value.length - selectionEnd

                const _value = Number(
                    inputEl.current.value.replace(/[^0-9]/g, '') || 0
                )

                const formattedValue = formatValue(_value)
                const newPosition = formattedValue.length - rightCharsCount

                onChange(Number(_value || 0))

                window.requestAnimationFrame(() => {
                    inputEl.current.setSelectionRange(newPosition, newPosition)
                })
            }}
            {...props}
        />
    )
}

export default CurrencyInput

