import React from 'react'
import { Affix, Button, Col, Popconfirm, Row } from 'antd'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import { Navigation, Wrapper } from './styles'
import { useMutation } from '@apollo/client'
import { GET_EVENTS_MAIN } from '@cms/events/graphql/queries/event'
import { DELETE_EVENT } from '@cms/events/graphql/mutations/event'
import { removeFromList } from '@cms/core/graphql/utils'

const Nav = () => {
    const { eventId } = useParams<any>()
    const { pathname } = useLocation()
    const baseUrl = `/evento/${eventId}/config/info`
    const history = useHistory()

    const [deleteEvent] = useMutation(DELETE_EVENT, {
        update: removeFromList({ list: 'events', Type: 'Event' }),
        refetchQueries: [{ query: GET_EVENTS_MAIN }]
    })

    const handleEventDelete = async () => {
        await deleteEvent({
            variables: { id: eventId },
            fetchPolicy: 'no-cache'
        }).then(() => {
            history.push('/')
        })
    }

    return (
        <Wrapper>
            <Affix offsetTop={0}>
                <Row className="header" justify="space-between">
                    <Col>
                        <Navigation>
                            <Col>
                                <Link
                                    className={
                                        pathname === baseUrl ? 'active-nav' : ''
                                    }
                                    to={baseUrl}
                                >
                                    Informações
                                </Link>
                                <Link
                                    className={
                                        pathname === baseUrl + '/adicional'
                                            ? 'active-nav'
                                            : ''
                                    }
                                    to={baseUrl + '/adicional'}
                                >
                                    Informações adicionais
                                </Link>
                            </Col>
                        </Navigation>
                    </Col>
                    <Col>
                        <Popconfirm
                            title="Deletar evento"
                            description="Você tem certeza que deseja deletar esse evento?"
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={() => handleEventDelete()}
                        >
                            <Button danger type="primary">
                                Deletar evento
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            </Affix>
        </Wrapper>
    )
}

export default Nav
