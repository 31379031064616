import { TConfig } from '../pages/Configs/types'
import { TSection } from '../pages/Theme/sections/types'

export enum COMPONENTS {
    Theme = 'Theme',
    Images = 'Images',
    Menu = 'Menu',
    Home = 'Home',
    Scripts = 'Scripts',
    Configs = 'Configs',
    OtherConfigs = 'OtherConfigs',
    Navigation = 'Navigation',
    Footer = 'Footer',
    MobileConfig = 'MobileConfig'
}

export type TComponent = keyof typeof COMPONENTS

export type TRoute = {
    path: string
    name: TComponent
    exact?: boolean
    home?: boolean
    title: string
    subTitle?: string
    sections?: TSection[] | TConfig[] | any
}

