import React, { useState } from 'react'

import Card, { CompareLines } from '@cms/events/pages/Events/Card'
import { StarOutlined } from '@ant-design/icons'
import { Empty, Select } from 'antd'
import { GET_SALES_BY_TICKET_TYPE_FINANCIAL } from '@cms/events/graphql/queries/financial'
import { useQuery } from '@apollo/client'
import { LegendSquare, LegendTitle } from '../../Card/styles'

export default function FinancialTickets({ eventId, offer, dateRange }) {
    const [type, setType] = useState('numeric')

    const { data: dataPaid } = useQuery(GET_SALES_BY_TICKET_TYPE_FINANCIAL, {
        variables: { eventId, offer, status: 'paid', ...dateRange }
    })

    const { data: dataPending } = useQuery(GET_SALES_BY_TICKET_TYPE_FINANCIAL, {
        variables: { eventId, offer, status: 'pending', ...dateRange }
    })

    const format = (sales) => ({
        name: sales.ticketType,
        percent: `${sales.percentage}%`,
        value: type === 'numeric' ? sales.count : `${sales.percentage}%`
    })
    const paidValues = dataPaid?.salesByTicketType?.map(format) || []
    const pendingValues = dataPending?.salesByTicketType?.map(format) || []

    return (
        <Card
            icon={StarOutlined}
            title="Tipos de ingresso"
            subTitle="(Quantidade ingressos)"
            options={
                <Select
                    placeholder="Selecione"
                    value={type}
                    onChange={(value) => setType(value)}
                >
                    <Select.Option value="numeric">
                        Valor númerico
                    </Select.Option>
                    <Select.Option value="percent">Parcentual</Select.Option>
                </Select>
            }
        >
            {!!paidValues.length && (
                <>
                    <LegendTitle>
                        <LegendSquare color="#00D394" />
                        Pagos
                    </LegendTitle>
                    <CompareLines values={paidValues} color="#00D394" />
                </>
            )}

            {!!pendingValues.length && (
                <>
                    <LegendTitle>
                        <LegendSquare color="#7F44FF" />
                        Pendentes
                    </LegendTitle>
                    <CompareLines values={pendingValues} color="#7F44FF" />
                </>
            )}
        </Card>
    )
}
