import React, { useContext, useEffect } from 'react'
import ReactQuill from 'react-quill'
import EditorToolbar, { modules, formats } from './EditorToolbar'
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.snow.css'
import { TextEditor } from './styles'
import { Quill } from 'react-quill'
import { usePageBuilderContext } from '../../context/context'
import { throttle } from 'lodash'
import { useRef } from 'react'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { Form } from 'antd'

const quillHandler = {
    'td-font': function (value) {
        this.quill.format('td-font', value)
    },
    modelFields: function (value) {
        const { index } = this.quill.getSelection()
        this.quill.insertText(index, '{{ref.' + value + '}}')
    }
}

const Block = Quill.import('blots/block')
Block.tagName = 'DIV'
Quill.register(Block, true)

const Parchment = Quill.import('parchment')
const TDFontClass = new Parchment.Attributor.Class('td-font', 'td-font', {
    scope: Parchment.Scope.INLINE
})
Quill.register(TDFontClass, true)

const ColorClass = Quill.import('attributors/class/color')
ColorClass.keyName = 'td-color'
Quill.register(ColorClass, true)

const AlignClass = Quill.import('attributors/class/align')
AlignClass.whitelist.push('left')
AlignClass.keyName = 'td-align'
Quill.register(AlignClass, true)

const Icons = Quill.import('ui/icons')
Icons.align.left = Icons.align['']
Quill.register(Icons, true)

const useParentPath = ({ id, formName, parentPath = [] }) => {
    if (!id) {
        return parentPath
    }

    let newId = id
    let listName = newId.match(/_(\d+)_/g)?.pop()

    if (listName) {
        newId = listName.replace(/^_/g, '') + newId.split(listName).pop()
    } else if (formName) {
        newId = newId.replace(formName + '_', '')
    }

    return newId.split('_').slice(0, -1)
}

const RichTextEditor = (props) => {
    const parentPath = useParentPath(props)

    const { colors, customColors, customFonts, site } = usePageBuilderContext()
    const debounceChange = useRef(throttle(props.onChange, 300)).current

    const [toolbarId] = useState('toolbarId-' + nanoid())

    return (
        <TextEditor
            colors={colors}
            customColors={customColors}
            customFonts={customFonts}
            font={site.theme.font}
        >
            <EditorToolbar
                parentPath={parentPath}
                toolbarId={toolbarId}
                colors={colors}
                customFonts={customFonts}
                customColors={customColors}
                allowVisibilityControl={props.allowVisibilityControl}
            />
            <ReactQuill
                {...props}
                onChange={debounceChange}
                defaultValue=""
                modules={{
                    toolbar: {
                        container: '#' + toolbarId,
                        handlers: quillHandler
                    },
                    history: {
                        delay: 500,
                        maxStack: 100,
                        userOnly: true
                    }
                }}
                // formats={formats}
            />
        </TextEditor>
    )
}

export default RichTextEditor
