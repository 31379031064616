import { gql } from '@apollo/client'

export * from '@cms/core/graphql/fragments'

export const MediaData = gql`
    fragment MediaData on Media {
        _id
        filename
        url
    }
`

export const IntegrationData = gql`
    fragment IntegrationData on Integration {
        id
        name
        category
        type
        active
        config
        privateConfig
    }
`

export const WatchConfigData = gql`
    fragment WatchConfigData on WatchConfig {
        domain {
            name
            validated
            error
        }
        theme
        scripts {
            header
            bodyStart
            bodyEnd
        }
        translation {
            languages
        }
        home {
            sections {
                type
                name
                active
                config
            }
        }
        loginBackgroundImage {
            ...MediaData
        }
        footerConfig {
            background
            links {
                label
                url
            }

            social {
                type
                url
            }
        }
        menus {
            main {
                name
                label
                url
                icon
                visible
            }
            user {
                name
                label
                url
                icon
                visible
            }
        }
    }

    ${MediaData}
`

export const WatchCategoryData = gql`
    fragment WatchCategoryData on WatchCategory {
        id
        name
        description
        image {
            ...MediaData
        }
        imageBanner {
            ...MediaData
        }
        imageHighlight {
            ...MediaData
        }
        parent {
            id
            name
            parent {
                id
                name
            }
        }
        children {
            totalCount
        }
        priority
        createdAt
        updatedAt
    }

    ${MediaData}
`

export const WatchExpertData = gql`
    fragment WatchExpertData on WatchExpert {
        id
        image {
            ...MediaData
        }
        name
        firstName
        lastName
        email
        bio
        company {
            name
            image {
                ...MediaData
            }
        }
        office
        links {
            type
            url
        }
        priority
        createdAt
        updatedAt
    }

    ${MediaData}
`

export const WatchContentData = gql`
    fragment WatchContentData on WatchContent {
        id
        title
        description
        duration
        image {
            ...MediaData
        }
        featureImage {
            ...MediaData
        }
        featureImageMobile {
            ...MediaData
        }
        module
        downloads {
            url
        }
        categories {
            ...WatchCategoryData
        }
        video {
            url
            player
        }
        featured
        experts {
            edges {
                node {
                    ...WatchExpertData
                }
            }
        }
        priority
        createdAt
        updatedAt
    }

    ${MediaData}
    ${WatchCategoryData}
    ${WatchExpertData}
`

export const WatchCourseData = gql`
    fragment WatchCourseData on WatchCourse {
        id
        title
        description
        image {
            ...MediaData
        }
        modules {
            _id
            name
            contents {
                ...WatchContentData
            }
        }
        createdAt
        updatedAt
    }

    ${MediaData}
    ${WatchContentData}
`

export const WatchProductData = gql`
    fragment WatchProductData on WatchProduct {
        id
        name
        description
        permissions {
            categories {
                fullAccess
                items {
                    ...WatchCategoryData
                }
            }
            courses {
                items {
                    ...WatchCourseData
                }
            }
        }
        value
        createdAt
        updatedAt
    }

    ${WatchCategoryData}
    ${WatchCourseData}
`

export const WatchPlanData = gql`
    fragment WatchPlanData on WatchPlan {
        id
        name
        products {
            ...WatchProductData
        }
        recurrency
        value
        createdAt
        updatedAt
    }

    ${WatchProductData}
`

export const UserWatchProductData = gql`
    fragment UserWatchProductData on UserWatchProduct {
        id
        product {
            ...WatchProductData
        }
        expiresAt
        createdAt
        updatedAt
    }

    ${WatchProductData}
`

