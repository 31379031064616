import React from 'react'

import BaseConfig from '../BaseConfig'

import Segment from '@cms/core/components/Segment'

import { HEADER_HERO_CONFIGS } from '@cms/core/components/Segment/constants/headerHero'

interface IHeaderProps {
    name: string
}

export function FeaturedHero({ name }: IHeaderProps) {
    return (
        <>
            <BaseConfig name={name} title={true} description={true} />
            <Segment
                name={[name, 'config']}
                type="header"
                filters={HEADER_HERO_CONFIGS}
                configs={{
                    formName: 'sections'
                }}
            />
        </>
    )
}
