import React from 'react'
import { Typography, Progress, Row, Empty } from 'antd'

import { WrapperLines } from './styles'

const { Text } = Typography

const CompareLines = ({ values, color = '#7730F6' }) => {
    if (!values.length) {
        return <Empty description="Não há dados" />
    }

    return (
        <WrapperLines>
            {values.map((value, index) => (
                <div style={{ marginBottom: '4px' }}>
                    <Row justify="space-between">
                        <Text style={{ color: '#fff' }}>{value.name}</Text>

                        <Text style={{ color: '#fff' }} strong>
                            {value.value}
                        </Text>
                    </Row>
                    <Progress
                        key={index}
                        percent={Number(value.percent.replace('%', ''))}
                        format={() =>
                            `${Number(value.percent.replace('%', ''))}%`
                        }
                        size="small"
                        showInfo={false}
                        success={{
                            percent: Number(value.percent.replace('%', '')),
                            strokeColor: color
                        }}
                    />
                </div>
            ))}
        </WrapperLines>
    )
}

export default CompareLines
