import React from 'react'
import { Form, Input, Radio } from 'antd'
import { DesktopOutlined, MobileOutlined } from '@ant-design/icons'
import { ColorsBadges } from '..'
import { Wrapper, Title } from '../../styles'
import { useState } from 'react'
import { ImageConfig } from './ImageConfig'
import { VideoConfig } from './VideoConfig'

const ContainerConfig = (props) => {
    const { bgVideo = true } = props
    const [mode, setMode] = useState('desktop')

    const handleModeChange = (e) => {
        setMode(e.target.value)
    }

    return (
        <Wrapper>
            <Form.Item
                name={[props.name, 'configs', 'container', 'id']}
                label="ID"
            >
                <Input />
            </Form.Item>

            <Title level={4}>Fundo</Title>
            <Form.Item
                name={[props.name, 'configs', 'container', 'bgColor']}
                label="Cor de fundo"
            >
                <ColorsBadges />
            </Form.Item>

            <Radio.Group
                onChange={handleModeChange}
                value={mode}
                initialValue={mode}
                style={{ marginBottom: 8 }}
            >
                <Radio.Button
                    style={{ borderRight: '1px solid rgb(255 255 255 / 100%)' }}
                    value="desktop"
                >
                    <DesktopOutlined />
                </Radio.Button>

                <Radio.Button value="mobile">
                    <MobileOutlined />
                </Radio.Button>
            </Radio.Group>

            {mode === 'desktop' && (
                <>
                    <ImageConfig name={props.name} type="bgImage" />
                    <VideoConfig
                        name={props.name}
                        type="bgVideo"
                        bgVideo={bgVideo}
                    />
                </>
            )}

            {mode === 'mobile' && (
                <>
                    <ImageConfig name={props.name} type="mobileBgImage" />
                    <VideoConfig
                        name={props.name}
                        type="mobileBgVideo"
                        bgVideo={bgVideo}
                    />
                </>
            )}
        </Wrapper>
    )
}

export default ContainerConfig
