import { Select } from 'antd'
import React from 'react'

import { useQuery } from '@apollo/client'
import { GET_SALES_OFFER_OPTIONS } from '@cms/events/graphql/queries/financial'
import Loader from '@cms/core/components/Loader'
import { useParams } from 'react-router-dom'

export default function FinancialFilterByOffer({ onChange, value }) {
    const { eventId } = useParams()

    const { data, error } = useQuery(GET_SALES_OFFER_OPTIONS, {
        variables: {
            eventId
        }
    })

    if (error) return `Error! ${error.message}`
    if (!data) return <Loader fixed={false} padding="10px" />

    return (
        <div style={{ marginBottom: '12px' }}>
            <Select
                placeholder="Todas as ofertas"
                value={value}
                onChange={onChange}
                style={{ width: '350px' }}
            >
                <Select.Option value={null}>Todas as ofertas</Select.Option>
                {data.salesOfferOptions.map((offer) => (
                    <Select.Option key={offer.id} value={offer.id}>
                        {offer.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    )
}
