import React, { useMemo } from 'react'
import { COLORS } from '../../constants/labels'
import { usePageBuilderContext } from '../../context/context'
import { Form, Switch } from 'antd'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { FormatEditorCustomProp } from './utils'

const QuillToolbar = ({
    toolbarId,
    colors,
    customColors,
    customFonts,
    parentPath = [],
    allowVisibilityControl = true
}) => {
    const { site, model } = usePageBuilderContext()
    const modelFields = useMemo(() => {
        return model?.fields.filter((field) => {
            return field.type !== 'image' && field.type !== 'array'
        })
    }, [model])

    return (
        <div id={toolbarId}>
            <span className="ql-formats">
                <select className="ql-td-font" defaultValue={''}>
                    <option value="">{site.theme.font}</option>
                    {customFonts.map(({ font, name }, index) => (
                        <option key={font} value={FormatEditorCustomProp(name)}>
                            {font} ({'Fonte ' + (index + 1)})
                        </option>
                    ))}
                </select>
            </span>
            <span className="ql-formats">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
                <button className="ql-strike" />
            </span>
            <span className="ql-formats">
                <select className="ql-color" defaultValue={''}>
                    <option></option>
                    {Object.keys(colors).map((key) => (
                        <option key={key} value={key}>
                            {COLORS[key]}
                        </option>
                    ))}
                    {customColors?.map(({ name }) => (
                        <option key={name} value={FormatEditorCustomProp(name)}>
                            {name}
                        </option>
                    ))}
                </select>
            </span>
            <span className="ql-formats">
                {/* <button className="ql-list" value="ordered" />
                <button className="ql-list" value="bullet" /> */}
                <select className="ql-align">
                    <option value="left"></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                </select>
            </span>

            <span className="ql-formats">
                <button className="ql-link" />
            </span>

            {allowVisibilityControl && (
                <span className="ql-formats">
                    <Form.Item
                        name={[...parentPath, 'active']}
                        valuePropName="checked"
                        initialValue={true}
                        noStyle
                    >
                        <Switch
                            checkedChildren={<EyeOutlined />}
                            unCheckedChildren={<EyeInvisibleOutlined />}
                        />
                    </Form.Item>
                </span>
            )}

            {modelFields?.length > 0 && (
                <select class="ql-modelFields">
                    {modelFields.map((field) => {
                        return (
                            <option key={field.name} value={field.name}>
                                {field.label}
                            </option>
                        )
                    })}
                </select>
            )}

            {/* <span className="ql-formats">
                <button className="ql-link" />
                <button className="ql-image" />
                <button className="ql-video" />
            </span> */}
            {/* <span className="ql-formats">
                    <select className="ql-font" defaultValue="arial">
                        <option value="arial">Arial</option>
                        <option value="comic-sans">Comic Sans</option>
                        <option value="courier-new">Courier New</option>
                        <option value="georgia">Georgia</option>
                        <option value="helvetica">Helvetica</option>
                        <option value="lucida">Lucida</option>
                    </select>
                    <select className="ql-size" defaultValue="medium">
                        <option value="extra-small">Size 1</option>
                        <option value="small">Size 2</option>
                        <option value="medium">Size 3</option>
                        <option value="large">Size 4</option>
                    </select>
                    <select className="ql-header" defaultValue="3">
                    <option value="1">Heading</option>
                    <option value="2">Subheading</option>
                    <option value="3">Normal</option>
                </select>
                </span> */}
            {/* <span className="ql-formats">
                <button className="ql-script" value="super" />
                <button className="ql-script" value="sub" />
                <button className="ql-blockquote" />
                <button className="ql-direction" />
            </span>

            <span className="ql-formats">
                <button className="ql-formula" />
                <button className="ql-code-block" />
                <button className="ql-clean" />
            </span> */}
        </div>
    )
}

export default QuillToolbar
