import React, { useEffect, useState } from 'react'
import { Button, Switch, Form, Popconfirm, Spin } from 'antd'
import {
    CaretUpOutlined,
    CaretDownOutlined,
    DeleteOutlined,
    EyeOutlined,
    DesktopOutlined,
    MobileOutlined,
    EyeInvisibleOutlined,
    EditOutlined
} from '@ant-design/icons'

import { LABELS } from '../../constants/labels'
import { Row, CustomInput } from './styles'
import { usePageBuilderContext } from '../../context/context'
import { set } from 'lodash'

const PanelHeader = (props) => {
    const {
        index,
        section,
        checked,
        form,
        activePanel,
        setSection,
        sectionName = 'sections',
        move,
        remove
    } = props
    const size = form.getFieldsValue(sectionName)[sectionName]?.length || 0
    const title = LABELS[section]
    const [fieldSaving, setFieldSaving] = useState(false)
    const { loading, setLoading } = usePageBuilderContext()

    const handleFormSubmit = () => {
        setLoading(true)
        form.submit().then(() => {
            setLoading(false)
        })
    }

    const handleClickUp = () => {
        move(index, index - 1)
        handleFormSubmit()
    }

    const handleClickDown = () => {
        move(index, index + 1)
        handleFormSubmit()
    }

    const onChecked = (e, index) => {
        handleFormSubmit()
    }

    const removeSection = (index) => {
        remove(index)
        handleFormSubmit()
    }

    const cancel = (e) => {}

    const handleTitleChange = (e) => {
        handleFormSubmit()
        setFieldSaving(true)
    }

    useEffect(() => {
        if (!loading) {
            setFieldSaving(false)
        }
    }, [loading])

    const enableUpClick = index > 0
    const enableDownClick = index < size - 1

    return (
        <Row style={{ width: '100%' }} justify="space-between" align="middle">
            <Row align="middle" justify="start">
                <div className="move-section">
                    {enableUpClick && (
                        <CaretUpOutlined onClick={handleClickUp} />
                    )}
                    {enableDownClick && (
                        <CaretDownOutlined onClick={handleClickDown} />
                    )}
                </div>

                <Form.Item
                    className="panel-header"
                    name={[index, 'name']}
                    initialValue={title}
                    valuePropName="value"
                >
                    <CustomInput
                        variant={'borderless'}
                        addonAfter={fieldSaving ? <Spin /> : <EditOutlined />}
                        value={title}
                        placeholder="Escolha um Título"
                        onBlur={(e) => {
                            handleTitleChange(e)
                        }}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                e.currentTarget.blur()
                            }
                        }}
                    />
                </Form.Item>
            </Row>

            <Row
                align="middle"
                justify="space-between"
                style={{ width: '185px' }}
            >
                {index >= 0 && (
                    <>
                        <Form.Item
                            className="panel-header"
                            name={[index, 'active']}
                            initialValue={checked}
                            valuePropName="checked"
                        >
                            <Switch
                                checked={checked}
                                checkedChildren={<DesktopOutlined />}
                                unCheckedChildren={<EyeInvisibleOutlined />}
                                onChange={onChecked}
                                size="small"
                            />
                        </Form.Item>

                        <Form.Item
                            className="panel-header"
                            name={[index, 'activeMobile']}
                            initialValue={checked}
                            valuePropName="checked"
                        >
                            <Switch
                                checked={checked}
                                checkedChildren={<MobileOutlined />}
                                unCheckedChildren={<EyeInvisibleOutlined />}
                                onChange={onChecked}
                                size="small"
                            />
                        </Form.Item>
                    </>
                )}
                {index === activePanel ? (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setSection(null)
                        }}
                    >
                        Fechar
                    </Button>
                ) : (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setSection(index)
                            // window.location.hash = `section-${section}`
                        }}
                    >
                        Editar
                    </Button>
                )}
                <Popconfirm
                    title="Você realmente quer remover esta seção?"
                    onConfirm={() => removeSection(index)}
                    onCancel={cancel}
                    okText="Sim"
                    cancelText="Não"
                >
                    <a href="#">
                        <DeleteOutlined />
                    </a>
                </Popconfirm>
            </Row>
        </Row>
    )
}

export default PanelHeader
