import { gql } from '@apollo/client'
import { UserWatchProductData } from '../fragments'

export const CREATE_USER_WATCH_PRODUCT = gql`
    mutation CreateUserWatchProduct(
        $user: ObjectID!
        $product: ObjectID!
        $expiresAt: DateTime
    ) {
        createUserWatchProduct(
            input: { user: $user, product: $product, expiresAt: $expiresAt }
        ) {
            ...UserWatchProductData
        }
    }
    ${UserWatchProductData}
`

export const UPDATE_USER_WATCH_PRODUCT = gql`
    mutation UpdateUserWatchProduct(
        $id: ObjectID!
        $user: ObjectID!
        $product: ObjectID!
        $expiresAt: DateTime
    ) {
        updateUserWatchProduct(
            id: $id
            input: { user: $user, product: $product, expiresAt: $expiresAt }
        ) {
            ...UserWatchProductData
        }
    }
    ${UserWatchProductData}
`

export const DELETE_USER_WATCH_PRODUCT = gql`
    mutation DeleteUserWatchProduct($id: ObjectID!) {
        deleteUserWatchProduct(id: $id) {
            id
            success
        }
    }
`

