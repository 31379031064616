import React from 'react'
import { Select } from 'antd'
import { googleFonts, systemFonts } from '@cms/core/themes/Fonts'

const { OptGroup, Option } = Select

const FontSelector = (props) => {
    return (
        <Select {...props}>
            <OptGroup label="Sistema">
                {systemFonts.map((font) => {
                    return (
                        <Option
                            value={font}
                            key={font}
                            style={{ fontFamily: font }}
                        >
                            {font}
                        </Option>
                    )
                })}
            </OptGroup>
            <OptGroup label="Google">
                {googleFonts.map((font) => {
                    return (
                        <Option
                            value={font}
                            key={font}
                            style={{ fontFamily: font }}
                        >
                            {font}
                        </Option>
                    )
                })}
            </OptGroup>
        </Select>
    )
}

export default FontSelector
