import React, { useState, useEffect, useRef } from 'react'
import {
    Form,
    Row,
    Col,
    Modal,
    Input,
    Popconfirm,
    Collapse,
    message,
    Select
} from 'antd'
import { Preview } from '../../components'
import { usePageBuilderContext } from '../../context/context'
import { List } from './sections/'
import SectionFilters from '../../components/SectionFilters'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import Seo from '../Seo'
import { StyledCollapse } from './styles'
import { useMutation } from '@apollo/client'
import { UPDATE_SITE } from '@cms/events/graphql/mutations/site'
import PageConfig from '../PageConfig'
import Title from 'antd/es/typography/Title'
import Affix from '@cms/core/components/Affix'
const { Panel } = Collapse

const Page = (props) => {
    const { name } = props
    const [form] = Form.useForm()
    const [activePanel, setActivePanel] = useState(null)
    const [mainSections, setMainSections] = useState()
    const previewRef = useRef(null)
    const colRef = useRef(null)
    const { eventId } = useParams()
    const baseUrl = `/evento/${eventId}/paginas/page`
    const { pathname } = useLocation()
    const {
        site,
        setSite,
        sectionsModalParams,
        setSectionsModalParams,
        setPreview,
        config,
        setPage,
        fieldRefValue,
        updateSite
    } = usePageBuilderContext()

    const history = useHistory()

    useEffect(() => {
        setPage(name)
        return () => {
            setPage(null)
        }
    }, [name])

    useEffect(() => {
        if (site?.pages && site.pages[name]) {
            console.log(site.pages[name])
            form.setFieldsValue(site.pages[name])
        }
    }, [site, name, form])

    useEffect(() => {
        if (!site) {
            return
        }

        if (!site.pages[name]) {
            history.push(baseUrl + '/home')
        }
    }, [site, name, baseUrl])

    if (!site?.pages[name]) {
        return null
    }

    const slug = site?.pages[name]?.slug || name

    const handleCancel = () => {
        setSectionsModalParams(null)
    }

    const updateSection = (index) => {
        const data = form.getFieldsValue()
        const section = data.sections[index]

        console.log(JSON.stringify(section))

        if (index === null) {
            return null
        }

        if (section && previewRef.current) {
            previewRef.current.handleParentWidth(colRef?.current?.offsetWidth)
            previewRef.current.Emit('pageData', {
                index,
                configs: section.configs,
                layout: {
                    theme: site.theme
                }
            })
        }
    }

    const onInit = (instance) => {
        setPreview(instance)
        if (activePanel !== null) {
            updateSection(activePanel)
        } else if (previewRef.current) {
            previewRef.current.Emit('fullPage', {
                ...site,
                sections: site.pages[name].sections
            })
            previewRef.current.handleParentWidth(colRef.current.offsetWidth)
            previewRef.current.handleParentHeight(600)
        }
    }

    const setSection = (index) => {
        setActivePanel(index)
        updateSection(index)
    }

    return (
        <Row gutter={24}>
            <Col md={12} sm={24}>
                {name !== 'home' && (
                    <StyledCollapse>
                        <Panel
                            header={<Title level={3}>{'Configurações'}</Title>}
                            key="1"
                        >
                            <PageConfig page={name} />
                        </Panel>
                    </StyledCollapse>
                )}

                <StyledCollapse>
                    <Panel header={<Title level={3}>{'SEO'}</Title>} key="2">
                        <Seo page={name} />
                    </Panel>
                </StyledCollapse>

                <Form
                    name={name}
                    form={form}
                    layout="vertical"
                    onValuesChange={() => {
                        setTimeout(() => {
                            updateSection(activePanel)
                        })
                    }}
                >
                    {site ? (
                        <>
                            <List
                                activePanel={activePanel}
                                setSection={setSection}
                                form={form}
                                page={name}
                            />
                        </>
                    ) : null}
                </Form>
                <Modal
                    title="Escolha uma seção"
                    open={sectionsModalParams}
                    onCancel={handleCancel}
                    footer={null}
                    width={1200}
                >
                    <SectionFilters name={name}></SectionFilters>
                </Modal>
            </Col>
            <Col md={12} sm={24} ref={colRef}>
                <Affix offsetTop={104}>
                    <div>
                        <div
                            style={{
                                visibility: activePanel !== null ? '' : 'hidden'
                            }}
                        >
                            <Preview
                                onInit={onInit}
                                ref={previewRef}
                                page={slug}
                                fieldRefValue={fieldRefValue}
                            />
                        </div>
                    </div>
                </Affix>
            </Col>
        </Row>
    )
}

export default Page
