import React from 'react'

import Nav from './components/Nav'

import InfoBuilderRoutes from './routes'

const Routes = () => (
    <>
        <Nav />
        <InfoBuilderRoutes />
    </>
)

export default Routes

