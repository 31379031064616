import React from 'react'
import { message, Form, Input, Select } from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'

import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'
import SelectSearch from '@cms/core/components/SelectSearch'

import { addToList } from '@cms/core/graphql/utils'
import { GET_FAQ_QUESTION } from '@cms/events/graphql/queries/faqQuestions'
import {
    CREATE_FAQ_QUESTION,
    UPDATE_FAQ_QUESTION
} from '@cms/events/graphql/mutations/faqQuestions'
import { GET_FAQ_TOPICS_LIST } from '@cms/events/graphql/queries/faqTopics'

const FaqQuestionsModal = ({ params, ...props }) => {
    const { eventId } = params
    const client = useApolloClient()

    const queryTopics = useQuery(GET_FAQ_TOPICS_LIST, {
        variables: { first: 9999, eventId }
    })

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_FAQ_QUESTION,
                variables: { id }
            })

            return { ...data.faqQuestion }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_FAQ_QUESTION,
                update: addToList({
                    list: 'faqQuestions',
                    Type: 'FaqQuestion'
                }),
                variables: {
                    ...formData,
                    topic: formData.topic.id,
                    event: eventId
                }
            })

            message.success('Pergunta enviada com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_FAQ_QUESTION,
                variables: {
                    id,
                    ...formData,
                    topic: formData.topic.id,
                    event: eventId
                }
            })

            message.success('Pergunta atulizada com sucesso!')
        }
    })

    const [topicsOptions, topicsSelectOptions] = useCrudSelect(queryTopics, {
        queryName: 'faqTopics',
        additionalValues: form.getFieldValue('topic')
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar pergunta' : 'Nova pergunta'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Pergunta"
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: 'Pergunta é obrigatória!'
                        }
                    ]}
                    {...getError('question')}
                >
                    <Input.TextArea autoFocus rows={2} />
                </Form.Item>

                <Form.Item
                    label="Resposta"
                    name="answer"
                    rules={[
                        {
                            required: true,
                            message: 'Resposta é obrigatória!'
                        }
                    ]}
                    {...getError('answer')}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                    label="Tópico"
                    name={['topic', 'id']}
                    rules={[
                        {
                            required: true,
                            message: 'Tópico é obrigatório!'
                        }
                    ]}
                >
                    <SelectSearch {...topicsSelectOptions} mode="single">
                        {topicsOptions({
                            key: 'node.id',
                            label: 'node.name'
                        })}
                    </SelectSearch>
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default FaqQuestionsModal

