import React, { FormEventHandler, useEffect, useState } from 'react'
import { Input, Select } from 'antd'
import { getIcon, languagesLabels } from './utils'
import { StyledOption, WrapperInoutTranslation } from './styles'
import { useAppLanguages } from '../Providers/AppConfigProvider/Hooks/useAppLanguages'
import { TPEditorHTML } from '@cms/events/components/EditorHTML/editor'

const defaultLanguages = ['pt-BR', 'es-ES', 'en-US']

export interface IInputTranslationProps {
    value?: {}
    onChange?: any //não sei qual tipagem usar
    mode?: 'input' | 'textArea' | 'html'
    languages?: []
}

export function InputTranslation({
    value,
    onChange,
    languages: _languages,
    mode = 'input'
}: IInputTranslationProps) {
    const [currentTranslation, setCurrentTranslation] =
        useState<string>('pt-BR')
    const [languages, setLanguages] = useState<string[]>(null)
    const { languages: configLanguages } = useAppLanguages()

    const handleChange = (e: any) => {
        onChange({
            ...value,
            [currentTranslation]: mode === 'html' ? e : e?.target?.value
        })
    }

    useEffect(() => {
        const _configLanguages = languages || configLanguages
        if (_configLanguages?.length) {
            setLanguages(_configLanguages)
            setCurrentTranslation(_configLanguages[0])
        } else {
            setLanguages(defaultLanguages)
            setCurrentTranslation(defaultLanguages[0])
        }
    }, [languages])

    if (!languages) return null

    const SelectLanguage = (
        <Select
            onChange={(value) => setCurrentTranslation(value)}
            value={currentTranslation}
        >
            {languages?.map((lang, index) => (
                <Select.Option value={lang} key={index}>
                    <StyledOption>
                        <img
                            style={{
                                width: '20px'
                            }}
                            src={getIcon(lang)}
                        />
                        {mode !== 'input' && (
                            <span style={{ marginLeft: '20px' }}>
                                {languagesLabels[lang]}
                            </span>
                        )}
                    </StyledOption>
                </Select.Option>
            ))}
        </Select>
    )

    if (mode === 'textArea') {
        return (
            <WrapperInoutTranslation className="input-translation--textArea">
                {SelectLanguage}
                <Input.TextArea
                    value={value?.[currentTranslation]}
                    onChange={handleChange}
                />
            </WrapperInoutTranslation>
        )
    }
    if (mode === 'html') {
        return (
            <WrapperInoutTranslation className="input-translation--html">
                {SelectLanguage}
                <TPEditorHTML
                    value={value?.[currentTranslation]}
                    onChange={handleChange}
                />
            </WrapperInoutTranslation>
        )
    }

    return (
        <WrapperInoutTranslation>
            <Input
                value={value?.[currentTranslation]}
                onChange={handleChange}
                addonBefore={SelectLanguage}
            />
        </WrapperInoutTranslation>
    )
}

