import React from 'react'

import { Checkbox, Form, Input, message } from 'antd'

import { useApolloClient, useQuery } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'
import { SectionCard } from '@cms/core/components/TDCards'
import CustomTree from '@cms/core/components/Tree'

import {
    CREATE_WATCH_PRODUCT,
    UPDATE_WATCH_PRODUCT
} from '@cms/watch/graphql/mutations/watchProduct'
import { GET_WATCH_CATEGORIES_LIST_ORDENABLE } from '@cms/watch/graphql/queries/watchCategory'
import { GET_WATCH_PRODUCT } from '@cms/watch/graphql/queries/watchProduct'
import { addToList } from '@cms/watch/graphql/utils'
import {
    getWatchCategoryTreeData,
    getWatchCourseTreeData
} from '@cms/watch/utils/common'
import { GET_WATCH_COURSES_ORDENABLE } from '@cms/watch/graphql/queries/watchCourse'

import { CurrencyInput } from '@cms/events/components/CurrencyInput'

const WatchProductsModal = ({ ...props }) => {
    const client = useApolloClient()

    const queryCategories = useQuery(GET_WATCH_CATEGORIES_LIST_ORDENABLE, {
        variables: { first: 99999, orderBy: { name: 'ASC' } }
    })
    const queryCourses = useQuery(GET_WATCH_COURSES_ORDENABLE, {
        variables: { first: 99999, orderBy: { title: 'ASC' } }
    })

    const watchCategories = queryCategories?.data?.watchCategories?.edges?.map(
        ({ node }) => ({ ...node })
    )
    const categoriesTreeData = getWatchCategoryTreeData(watchCategories)

    const watchCourses = queryCourses?.data?.watchCourses?.edges?.map(
        ({ node }) => ({ ...node })
    )
    const coursesTreeData = getWatchCourseTreeData(watchCourses)

    const mapWatchProduct = (id, formData) => {
        const {
            permissions: { categories, courses },
            ...rest
        } = formData

        return {
            id,
            ...rest,
            permissions: {
                categories: {
                    fullAccess: categories.fullAccess,
                    items: categories?.items || []
                },
                courses: {
                    items: courses?.items || []
                }
            }
        }
    }

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const {
                data: { watchProduct }
            } = await client.query({
                query: GET_WATCH_PRODUCT,
                variables: { id }
            })

            const { categories, courses } = watchProduct.permissions

            return {
                ...watchProduct,
                permissions: {
                    categories: {
                        ...categories,
                        items: categories.items.map((m) => m.id)
                    },
                    courses: {
                        items: courses.items.map((m) => m.id)
                    }
                }
            }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_WATCH_PRODUCT,
                update: addToList({
                    list: 'watchProducts',
                    Type: 'WatchProduct'
                }),
                variables: mapWatchProduct(undefined, formData)
            })

            message.success('Produto cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_WATCH_PRODUCT,
                variables: mapWatchProduct(id, formData)
            })

            message.success('Produto atualizado com sucesso!')
        }
    })

    const currency = Form.useWatch(['value'], form)

    return (
        <CrudModal
            title={isEdit ? 'Editar Produto' : 'Novo Produto'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Descrição"
                    name="description"
                    {...getError('description')}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Valor" name="value" {...getError('value')}>
                    <CurrencyInput currency={currency} />
                </Form.Item>

                <SectionCard title="Permissões categorias">
                    <Form.Item
                        name={['permissions', 'categories', 'fullAccess']}
                        {...getError('fullAccess')}
                        valuePropName="checked"
                    >
                        <Checkbox>Acesso Total</Checkbox>
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            if (
                                getFieldValue([
                                    'permissions',
                                    'categories',
                                    'fullAccess'
                                ])
                            )
                                return null

                            return (
                                <Form.Item
                                    label="Categorias"
                                    name={[
                                        'permissions',
                                        'categories',
                                        'items'
                                    ]}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Categoria é obrigatória!'
                                        }
                                    ]}
                                    {...getError('items')}
                                >
                                    <CustomTree
                                        placeholder="Selecione as categorias"
                                        treeData={categoriesTreeData}
                                        autoSelectParent
                                        autoExpandParent
                                    />
                                </Form.Item>
                            )
                        }}
                    </Form.Item>
                </SectionCard>

                <SectionCard title="Permissões cursos">
                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            return (
                                <Form.Item
                                    label="Cursos"
                                    name={['permissions', 'courses', 'items']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Curso é obrigatório!'
                                        }
                                    ]}
                                    {...getError('items')}
                                >
                                    <CustomTree
                                        placeholder="Selecione os cursos"
                                        treeData={coursesTreeData}
                                        autoSelectParent
                                        autoExpandParent
                                    />
                                </Form.Item>
                            )
                        }}
                    </Form.Item>
                </SectionCard>
            </Form>
        </CrudModal>
    )
}

export default WatchProductsModal

