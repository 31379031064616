import React from 'react'
import { MobileProvider, useMobileContext } from './context'
import { ThemeBuilderContent } from '@cms/core/components/ThemeBuilder/components/Content'
import { BASE_URL, ROUTES } from './constants/routes'
import { ThemeBuilderProvider } from '@cms/core/components/ThemeBuilder'

const MobileConfigContent = () => {
    const { save } = useMobileContext()

    return <ThemeBuilderContent save={save} />
}

const MobileConfigScreen = () => {
    return (
        <ThemeBuilderProvider
            routesConfig={{ baseUrl: BASE_URL, routes: ROUTES }}
            previewUrl={''}
        >
            <MobileProvider>
                <MobileConfigContent />
            </MobileProvider>
        </ThemeBuilderProvider>
    )
}

export default MobileConfigScreen

