import React from 'react'

import { Form, Input, message, Select } from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { GET_WATCH_PLAN } from '@cms/watch/graphql/queries/watchPlan'

import {
    CREATE_WATCH_PLAN,
    UPDATE_WATCH_PLAN
} from '@cms/watch/graphql/mutations/watchPlan'

import { addToList } from '@cms/watch/graphql/utils'
import CurrencyInput from '@cms/events/components/CurrencyInput'
import { GET_WATCH_PRODUCTS_LIST_ORDENABLE } from '@cms/watch/graphql/queries/watchProduct'
import { getWatchProductTreeData } from '@cms/watch/utils/common'
import CustomTree from '@cms/core/components/Tree'

const recurrencies = [
    {
        label: 'Mensal',
        value: 'MONTHLY'
    }
]

const WatchPlansModal = ({ ...props }) => {
    const client = useApolloClient()

    const queryProducts = useQuery(GET_WATCH_PRODUCTS_LIST_ORDENABLE, {
        variables: { first: 99999, orderBy: { createdAt: 'DESC' } }
    })
    const watchProducts = queryProducts?.data?.watchProducts?.edges?.map(
        ({ node }) => ({ ...node })
    )
    const productsTreeData = getWatchProductTreeData(watchProducts)

    const mapWatchPlan = (id, formData) => {
        return {
            id,
            ...formData
        }
    }

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const {
                data: { watchPlan }
            } = await client.query({
                query: GET_WATCH_PLAN,
                variables: { id }
            })

            return {
                ...watchPlan,
                products: watchPlan?.products?.map((p) => p.id)
            }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_WATCH_PLAN,
                update: addToList({ list: 'watchPlans', Type: 'WatchPlan' }),
                variables: mapWatchPlan(undefined, formData)
            })

            message.success('Plano cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_WATCH_PLAN,
                variables: mapWatchPlan(id, formData)
            })

            message.success('Plano atualizado com sucesso!')
        }
    })

    const currency = Form.useWatch(['value'], form)

    return (
        <CrudModal
            title={isEdit ? 'Editar Plano' : 'Novo Plano'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Valor" name="value" {...getError('value')}>
                    <CurrencyInput currency={currency} />
                </Form.Item>

                <Form.Item
                    label="Recorrência"
                    name="recurrency"
                    rules={[
                        {
                            required: true,
                            message: 'Recorrência é obrigatória!'
                        }
                    ]}
                    {...getError('recurrency')}
                >
                    <Select>
                        {recurrencies.map((rec) => (
                            <Select.Option
                                key={rec.value}
                                value={rec.value}
                                title={rec.label}
                            >
                                {rec.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {() => {
                        return (
                            <Form.Item
                                label="Produtos"
                                name={['products']}
                                {...getError('products')}
                            >
                                <CustomTree
                                    placeholder="Selecione os produtos"
                                    treeData={productsTreeData}
                                    autoSelectParent
                                    autoExpandParent
                                />
                            </Form.Item>
                        )
                    }}
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default WatchPlansModal

