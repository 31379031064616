import React from 'react'
import { Collapse, Form, Input, Select, Switch } from 'antd'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { get } from 'lodash'

import { Title } from '../../styles'
import { ColorsBadges, WrapperConfig, RedirectOptions } from '..'
import { usePageBuilderContext } from '../../context/context'
import RichTextEditor from '../RichTextEditor/Editor'

const { Panel } = Collapse

const ButtonConfig = (props) => {
    const {
        enableId = true,
        enableText = true,
        enableActions,
        enableFont = true,
        inner,
        enableActiveStyling = false,
        allowVisibilityControl = false,
        defaultAction = 'formModal',
        rootPath = ['sections']
    } = props
    const { customFonts, site } = usePageBuilderContext()
    const fieldPath = props.fieldPath || [props.name, 'configs', 'button']

    const getNode = (obj, path) => {
        const _path = path.splice(1, path.length - 1)
        return get(obj, _path)
    }

    const handleShowUrl = (prevValues, currentValues) => {
        const pathType = [...fieldPath, 'action', 'type']

        const prevValue = getNode(prevValues.sections[props.name], pathType)
        const currentValue = getNode(
            currentValues.sections[props.name],
            pathType
        )

        return prevValue !== currentValue
    }

    return (
        <Collapse>
            <Panel
                header={<Title level={4}>{props.title || 'Botão'}</Title>}
                extra={
                    <>
                        {allowVisibilityControl ? (
                            <Form.Item
                                className="panel-header"
                                name={[...fieldPath, 'active']}
                                valuePropName="checked"
                                initialValue={false}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                            >
                                <Switch
                                    checkedChildren={<EyeOutlined />}
                                    unCheckedChildren={<EyeInvisibleOutlined />}
                                />
                            </Form.Item>
                        ) : null}
                    </>
                }
            >
                {/* <WrapperConfig title={props.title || 'Botão'} inner={inner}> */}
                {enableId && (
                    <Form.Item name={[...fieldPath, 'id']} label="ID">
                        <Input />
                    </Form.Item>
                )}
                {enableText && (
                    <Form.Item name={[...fieldPath, 'text']} label="Texto">
                        {/* <Input /> */}
                        <RichTextEditor allowVisibilityControl={false} />
                    </Form.Item>
                )}
                {enableActions && (
                    <RedirectOptions
                        fieldPath={[...fieldPath]}
                        path={rootPath}
                    />
                )}
                {enableFont && (
                    <Form.Item
                        name={[...fieldPath, 'fontFamily']}
                        label="Fonte"
                    >
                        <Select defaultValue={site.theme.font}>
                            <Option value="">{site.theme.font}</Option>
                            {customFonts.map(({ font, name }, index) => (
                                <Option key={name} value={name}>
                                    {font} ({'Fonte ' + (index + 1)})
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                <Form.Item
                    name={[...fieldPath, 'bgColor']}
                    label="Cor de fundo"
                >
                    <ColorsBadges />
                </Form.Item>
                <Form.Item
                    name={[...fieldPath, 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>

                <WrapperConfig
                    title={'Estilo ao passar o cursor'}
                    inner={inner}
                >
                    <Form.Item
                        name={[...fieldPath, 'hoverStyles', 'bgColor']}
                        label="Cor de fundo"
                    >
                        <ColorsBadges />
                    </Form.Item>
                    <Form.Item
                        name={[...fieldPath, 'hoverStyles', 'textColor']}
                        label="Cor do texto"
                    >
                        <ColorsBadges />
                    </Form.Item>
                </WrapperConfig>

                {enableActiveStyling && (
                    <WrapperConfig title={'Estilo quanto ativo'} inner={inner}>
                        <Form.Item
                            name={[...fieldPath, 'activeStyles', 'bgColor']}
                            label="Cor de fundo"
                        >
                            <ColorsBadges />
                        </Form.Item>
                        <Form.Item
                            name={[...fieldPath, 'activeStyles', 'textColor']}
                            label="Cor do texto"
                        >
                            <ColorsBadges />
                        </Form.Item>
                    </WrapperConfig>
                )}
            </Panel>
        </Collapse>
    )
}

export default ButtonConfig
