import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_SUBSCRIPTIONS_LIST = gql`
    query eventRegistrations(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $orderBy: EventRegistrationOrderBy
        $search: String
        $segment: EventRegistrationSegment
    ) {
        eventRegistrations(
            first: $first
            after: $afterCursor
            filter: { event: $eventId }
            orderBy: $orderBy
            search: $search
            segment: $segment
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...EventRegistrationData
                }
            }
        }
    }
    ${fragments.EventRegistrationData}
`

export const GET_SUBSCRIPTION_DETAIL = gql`
    query GetSubscriptionDetail($id: ObjectID!) {
        eventRegistration(id: $id) {
            createdAt
            event {
                fields {
                    type
                    name
                    label
                }
            }
            referral {
                source
            }
            referralReports {
                total
            }
            publicUrl
            data
            user {
                id
                name
                email
                phone
                company
                jobTitle
                department
                role
                avatar {
                    ...MediaData
                }
                networks {
                    totalCount
                }
            }
        }
    }
    ${fragments.MediaData}
`
