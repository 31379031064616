import React from 'react'
import { useParams } from 'react-router-dom'

import { message, Form, Select, DatePicker } from 'antd'
import dayjs from 'dayjs'

import { useApolloClient, useQuery } from '@apollo/client'

import { GET_WATCH_PRODUCTS_LIST_ORDENABLE } from '@cms/watch/graphql/queries/watchProduct'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import {
    GET_USER_WATCH_PRODUCT,
    GET_USER_WATCH_PRODUCTS_LIST_ORDENABLE
} from '@cms/watch/graphql/queries/userWatchProduct'
import {
    CREATE_USER_WATCH_PRODUCT,
    UPDATE_USER_WATCH_PRODUCT
} from '@cms/watch/graphql/mutations/userWatchProduct'

const WatchUserProductModal = ({ ...props }) => {
    const { userId } = props.params

    const client = useApolloClient()

    const queryProducts = useQuery(GET_WATCH_PRODUCTS_LIST_ORDENABLE, {
        variables: { first: 99999, orderBy: { createdAt: 'DESC' } }
    })
    const watchProducts = queryProducts?.data?.watchProducts?.edges?.map(
        ({ node }) => ({ ...node })
    )

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const response = await client.query({
                query: GET_USER_WATCH_PRODUCT,
                variables: { id }
            })

            const { userWatchProduct } = response?.data

            return {
                ...userWatchProduct,
                product: userWatchProduct?.product?.id,
                expiresAt:
                    userWatchProduct?.expiresAt &&
                    dayjs(new Date(userWatchProduct.expiresAt))
            }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_USER_WATCH_PRODUCT,
                variables: { ...formData, user: userId },
                refetchQueries: [
                    {
                        query: GET_USER_WATCH_PRODUCTS_LIST_ORDENABLE,
                        variables: {
                            first: 9999,
                            user: userId,
                            orderBy: { createdAt: 'DESC' }
                        }
                    }
                ]
            })

            message.success('Produto adicionado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_USER_WATCH_PRODUCT,
                variables: {
                    id,
                    ...formData,
                    user: userId
                },
                refetchQueries: [
                    {
                        query: GET_USER_WATCH_PRODUCTS_LIST_ORDENABLE,
                        variables: {
                            first: 9999,
                            user: userId,
                            orderBy: { createdAt: 'DESC' }
                        }
                    }
                ]
            })

            message.success('Produto atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Produto' : 'Adicionar Produto'}
            {...modalProps}
            {...props}
            width={650}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Produto"
                    name="product"
                    rules={[
                        {
                            required: true,
                            message: 'Produto é obrigatório!'
                        }
                    ]}
                    {...getError('product')}
                >
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {watchProducts?.map((o) => (
                            <Select.Option key={o.id} value={o.id}>
                                {o.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Expiração"
                    name="expiresAt"
                    {...getError('expiresAt')}
                >
                    <DatePicker
                        needConfirm={false}
                        style={{
                            width: '100%'
                        }}
                        format={'DD MMM YYYY'}
                        placeholder=""
                    />
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default WatchUserProductModal

