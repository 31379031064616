import { gql } from '@apollo/client'
import { WatchContentData } from '../fragments'

export const CREATE_WATCH_CONTENT = gql`
    mutation CreateWatchContent(
        $title: String!
        $description: String
        $image: MediaInput
        $featureImage: MediaInput
        $featureImageMobile: MediaInput
        $module: WatchContentModule
        $duration: Int
        $downloads: [MediaInput]
        $categories: [ObjectID!]!
        $video: WatchContentVideoInput
        $featured: Boolean
        $priority: Int!
        $experts: [ObjectID!]!
    ) {
        createWatchContent(
            input: {
                title: $title
                description: $description
                image: $image
                featureImage: $featureImage
                featureImageMobile: $featureImageMobile
                module: $module
                downloads: $downloads
                categories: $categories
                video: $video
                duration: $duration
                featured: $featured
                priority: $priority
                experts: $experts
            }
        ) {
            ...WatchContentData
        }
    }

    ${WatchContentData}
`

export const UPDATE_WATCH_CONTENT = gql`
    mutation UpdateWatchContent(
        $id: ObjectID!
        $title: String!
        $description: String
        $image: MediaInput
        $featureImage: MediaInput
        $featureImageMobile: MediaInput
        $module: WatchContentModule
        $duration: Int
        $downloads: [MediaInput]
        $categories: [ObjectID!]!
        $video: WatchContentVideoInput
        $featured: Boolean
        $priority: Int!
        $experts: [ObjectID!]!
    ) {
        updateWatchContent(
            id: $id
            input: {
                title: $title
                description: $description
                image: $image
                featureImage: $featureImage
                featureImageMobile: $featureImageMobile
                module: $module
                downloads: $downloads
                categories: $categories
                video: $video
                duration: $duration
                featured: $featured
                priority: $priority
                experts: $experts
            }
        ) {
            ...WatchContentData
        }
    }

    ${WatchContentData}
`

export const DELETE_WATCH_CONTENT = gql`
    mutation DeleteWatchContent($id: ObjectID!) {
        deleteWatchContent(id: $id) {
            id
            success
        }
    }
`

export const IMPORT_WATCH_CONTENT = gql`
    mutation ImportWatchContent($categoryId: ObjectID!, $talks: [ObjectID!]!) {
        importEventsContentToWatch(
            input: { watchCategoryId: $categoryId, talks: $talks }
        )
    }
`
