import React from 'react'

import { useQuery } from '@apollo/client'

import { GET_BRANDS_ACTION } from '@cms/events/graphql/queries/brand'

import { StarOutlined } from '@ant-design/icons'

import Card, { CompareLines } from '@cms/events/pages/Events/Card'

import { Empty } from 'antd'

const ClicksLinkPerCard = ({ eventId, brandId, metric }) => {
    const { loading, error, data } = useQuery(GET_BRANDS_ACTION, {
        variables: { eventId, brandId }
    })

    if (loading || error) return null

    const values =
        data.brandAction.length > 0
            ? data.brandAction.map((action) => {
                  console.log(action)
                  return {
                      name: action.name,
                      percent: action.percentage || '0%',
                      value: action.total
                  }
              })
            : []

    return (
        <>
            <Card
                icon={StarOutlined}
                title={'Click nos links'}
                style={{
                    marginRight: '5px'
                }}
            >
                <CompareLines title={metric.label} values={values} />
            </Card>
        </>
    )
}

export default ClicksLinkPerCard
