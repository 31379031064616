import React, { useEffect } from 'react'

import Modal from '@cms/core/components/ModalFull'
import ModalContent from './modalContent'

const WatchUserProductsModal = ({ params }) => {
    useEffect((_) => {
        document.body.style.overflowY = 'hidden'
        return (_) => {
            document.body.style.overflowY = ''
        }
    }, [])

    return (
        <Modal
            disableFooter={true}
            visible={true}
            showMenu={false}
            children={<ModalContent params={params} />}
        />
    )
}

export default WatchUserProductsModal

