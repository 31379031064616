import React from 'react'

import { useQuery } from '@apollo/client'

import { GET_BRAND_BY_COUNTRY } from '@cms/events/graphql/queries/brand'

import { StarOutlined } from '@ant-design/icons'

import Card, { CompareLines } from '@cms/events/pages/Events/Card'
import { Empty } from 'antd'

const UserPerCountryCard = ({ eventId, brandId, metric }) => {
    const { loading, error, data } = useQuery(GET_BRAND_BY_COUNTRY, {
        variables: { eventId, brandId }
    })

    if (loading || error) return null

    const values =
        data.brandByCountry.length > 0
            ? data.brandByCountry.map((material) => {
                  return {
                      name: material.name || 'Desconhecido',
                      percent: material.percentage || '0%',
                      value: material.total
                  }
              })
            : []

    return (
        <>
            <Card
                icon={StarOutlined}
                title={'Usuários por País'}
                style={{
                    marginRight: '5px'
                }}
            >
                <CompareLines title={metric.label} values={values} />
            </Card>
        </>
    )
}

export default UserPerCountryCard
