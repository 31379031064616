import { gql } from '@apollo/client'
import * as fragments from '@cms/core/graphql/fragments'

export const CREATE_CUSTOMER = gql`
    mutation CreateCustomer(
        $name: String
        $email: String
        $cnpj: String
        $paymentGateway: PaymentGateway
        $splitPercentage: Int
        $splitRecipient: JSON
        $emailableApiKey: String
        $modules: [CustomerModules!]
        $db: String
        $permissions: CustomerPermissionsInputs
        $billingPlan: ObjectID
    ) {
        createCustomer(
            input: {
                name: $name
                email: $email
                cnpj: $cnpj
                modules: $modules
                paymentGateway: $paymentGateway
                splitPercentage: $splitPercentage
                splitRecipient: $splitRecipient
                emailableApiKey: $emailableApiKey
                db: $db
                permissions: $permissions
                billingPlan: $billingPlan
            }
        ) {
            ...CustomerData
        }
    }

    ${fragments.CustomerData}
`

export const UPDATE_CUSTOMER = gql`
    mutation UpdateCustomer(
        $id: ObjectID!
        $name: String
        $email: String
        $cnpj: String
        $paymentGateway: PaymentGateway
        $splitPercentage: Int
        $splitRecipient: JSON
        $emailableApiKey: String
        $db: String
        $modules: [CustomerModules!]
        $enableMobile: Boolean
        $permissions: CustomerPermissionsInputs
        $logo: MediaInput
        $blocked: Boolean
        $billingPlan: ObjectID
    ) {
        updateCustomer(
            id: $id
            input: {
                name: $name
                email: $email
                cnpj: $cnpj
                modules: $modules
                paymentGateway: $paymentGateway
                splitPercentage: $splitPercentage
                splitRecipient: $splitRecipient
                emailableApiKey: $emailableApiKey
                db: $db
                permissions: $permissions
                enableMobile: $enableMobile
                logo: $logo
                blocked: $blocked
                billingPlan: $billingPlan
            }
        ) {
            ...CustomerData
        }
    }

    ${fragments.CustomerData}
`

export const UPDATE_CURRENT_CUSTOMER = gql`
    mutation UpdateCurrentCustomer($name: String, $logo: MediaInput) {
        updateCurrentCustomer(input: { name: $name, logo: $logo }) {
            ...CustomerData
        }
    }

    ${fragments.CustomerData}
`

export const DELETE_CUSTOMER = gql`
    mutation DeleteCustomer($id: ObjectID!) {
        deleteCustomer(id: $id) {
            id
            success
        }
    }
`
