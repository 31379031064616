import React from 'react'
import { Form, Select, Tooltip, Input } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { OPTIONS_POSITION, OPTIONS_SIZE, VIDEO_LABELS } from './constants'
import { Title, Wrapper } from '../../styles'

export function VideoConfig(props) {
    const { name, type, bgVideo } = props

    if (!bgVideo) {
        return null
    }

    return (
        <Wrapper>
            <Title level={4}>Configurações de vídeo de fundo</Title>
            <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, setFieldValue }) => {
                    const videoUrl = getFieldValue([
                        'sections',
                        name,
                        'configs',
                        'container',
                        type
                    ])

                    if (typeof videoUrl === 'string') {
                        setFieldValue(
                            ['sections', name, 'configs', 'container', type],
                            {
                                src: videoUrl,
                                objectFit: 'cover',
                                objectPosition: 'center center'
                            }
                        )
                    }

                    return (
                        <Form.Item
                            name={[name, 'configs', 'container', type, 'src']}
                            label={VIDEO_LABELS[type].label}
                            initialValue={videoUrl}
                        >
                            <Input />
                        </Form.Item>
                    )
                }}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                    const videoUrl = getFieldValue([
                        'sections',
                        name,
                        'configs',
                        'container',
                        type,
                        'src'
                    ])

                    return videoUrl ? (
                        <>
                            <Form.Item
                                name={[
                                    name,
                                    'configs',
                                    'container',
                                    type,
                                    'objectFit'
                                ]}
                                label={
                                    <Tooltip
                                        title={
                                            VIDEO_LABELS[type].objectFitTooltop
                                        }
                                    >
                                        Tamanho <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                                initialValue={'cover'}
                            >
                                <Select options={OPTIONS_SIZE} />
                            </Form.Item>

                            <Form.Item
                                name={[
                                    name,
                                    'configs',
                                    'container',
                                    type,
                                    'objectPosition'
                                ]}
                                label={
                                    <Tooltip
                                        title={
                                            VIDEO_LABELS[type].objectPosition
                                        }
                                    >
                                        Posição <InfoCircleOutlined />
                                    </Tooltip>
                                }
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                                initialValue={'center center'}
                            >
                                <Select options={OPTIONS_POSITION} />
                            </Form.Item>
                        </>
                    ) : null
                }}
            </Form.Item>
        </Wrapper>
    )
}
