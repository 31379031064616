import styled from 'styled-components'

import { COLORS } from '@cms/core/themes'

export const MobilePanelContainer = styled.div`
    padding: 18px;
    border: 2px solid rgba(228, 236, 255, 0.1);
    border-radius: 20px;
    margin-bottom: 20px;
    background-color: ${COLORS.bgLight};
`

export const MobilePanelContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

