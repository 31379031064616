import React from 'react'
import {
    message,
    Form,
    Row,
    Col,
    Input,
    Checkbox,
    Button,
    InputNumber,
    Select,
    Typography
} from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import Card from '@cms/core/components/Card'

import { addToList } from '@cms/core/graphql/utils'

import {
    CurrencyInput,
    formatCurrency
} from '@cms/events/components/CurrencyInput'
import { currencies } from '@cms/events/components/CurrencyInput/currencies'

import { GET_TICKET_OFFER } from '@cms/events/graphql/queries/ticketOffers'
import {
    CREATE_TICKET_OFFER,
    UPDATE_TICKET_OFFER
} from '@cms/events/graphql/mutations/tickerOffers'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import {
    DeleteOutlined,
    MinusCircleOutlined,
    PlusOutlined
} from '@ant-design/icons'
import { GET_TICKET_LIST_ORDENABLE } from '@cms/events/graphql/queries/tickets'

const TicketOfferModal = ({ params, ...props }) => {
    const { eventId } = params

    const client = useApolloClient()

    const queryTickets = useQuery(GET_TICKET_LIST_ORDENABLE, {
        variables: { first: 99999, eventId, orderBy: { createdAt: 'DESC' } }
    })

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const response = await client.query({
                query: GET_TICKET_OFFER,
                variables: { id, eventId }
            })

            const data = { ...response.data.ticketOffer }
            data.tickets =
                data.tickets?.map((o) => ({
                    ...o,
                    ticket: o.ticket.id
                })) || []

            return data
        },
        createData: async (formData) => {
            const data = formData
            await client.mutate({
                mutation: CREATE_TICKET_OFFER,
                update: addToList({
                    list: 'ticketOffers',
                    Type: 'TicketOffer'
                }),
                variables: { ...data, event: eventId, version: 2 }
            })

            message.success('Oferta cadastrada com sucesso!')
        },
        updateData: async (id, formData) => {
            const data = formData

            await client.mutate({
                mutation: UPDATE_TICKET_OFFER,
                variables: { id, ...data, event: eventId }
            })

            message.success('Oferta atualizada com sucesso!')
        }
    })

    return (
        <CrudModal
            width={820}
            title={isEdit ? 'Editar Oferta' : 'Nova Oferta'}
            {...modalProps}
            {...props}
        >
            <Form
                layout={'vertical'}
                form={form}
                initialValues={{
                    tickets: [
                        {
                            ticket: null,
                            unitPrice: null,
                            unlimited: false,
                            min: null,
                            max: null
                        }
                    ]
                }}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome da Oferta"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Nome é obrigatório!'
                                }
                            ]}
                            {...getError('name')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Slug"
                            name="slug"
                            rules={[
                                {
                                    required: true,
                                    message: 'Slug é obrigatório!'
                                }
                            ]}
                            {...getError('slug')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Imagem"
                            name={['image']}
                            {...getError('image')}
                        >
                            <MediaUploader
                                options={{
                                    optimize: {
                                        resize: {
                                            width: 900,
                                            height: 500
                                        },
                                        quality: 80
                                    }
                                }}
                                type="image"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Card title="Ingressos">
                            <Form.List name="tickets">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => {
                                            return (
                                                <Card
                                                    title={
                                                        <Row
                                                            align="middle"
                                                            justify="space-between"
                                                        >
                                                            <Col span={22}>
                                                                <Typography.Text>
                                                                    Ingresso{' '}
                                                                    {index + 1}
                                                                </Typography.Text>
                                                            </Col>

                                                            <Col>
                                                                <DeleteOutlined
                                                                    onClick={() =>
                                                                        remove(
                                                                            field.name
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    }
                                                    key={`tickets.${index}`}
                                                >
                                                    <Row
                                                        className="actionsBox"
                                                        gutter={24}
                                                        align="middle"
                                                    >
                                                        <Col span={24}>
                                                            <Row gutter={24}>
                                                                <Col span={18}>
                                                                    <Form.Item
                                                                        label="Ingresso"
                                                                        name={[
                                                                            field.name,
                                                                            'ticket'
                                                                        ]}
                                                                    >
                                                                        <Select>
                                                                            {queryTickets?.data?.tickets?.edges?.map(
                                                                                ({
                                                                                    node
                                                                                }) => (
                                                                                    <Select.Option
                                                                                        key={
                                                                                            node.id
                                                                                        }
                                                                                        value={
                                                                                            node.id
                                                                                        }
                                                                                        title={
                                                                                            node.name
                                                                                        }
                                                                                    >
                                                                                        {node.name +
                                                                                            '  (' +
                                                                                            formatCurrency(
                                                                                                node.value,
                                                                                                currencies[0]
                                                                                                    .symbol
                                                                                            ) +
                                                                                            ')'}
                                                                                    </Select.Option>
                                                                                )
                                                                            )}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Form.Item
                                                                        label="Preço unitário"
                                                                        name={[
                                                                            field.name,
                                                                            'unitPrice'
                                                                        ]}
                                                                        initialValue={
                                                                            0
                                                                        }
                                                                    >
                                                                        <CurrencyInput
                                                                            currency={
                                                                                currencies[0]
                                                                                    .symbol
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            <Row
                                                                gutter={[
                                                                    24, 24
                                                                ]}
                                                                align="middle"
                                                            >
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        name={[
                                                                            field.name,
                                                                            'unlimited'
                                                                        ]}
                                                                        valuePropName="checked"
                                                                        initialValue={
                                                                            false
                                                                        }
                                                                        noStyle
                                                                    >
                                                                        <Checkbox>
                                                                            Ilimitado
                                                                        </Checkbox>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label="Qtd. Mínima"
                                                                        name={[
                                                                            field.name,
                                                                            'min'
                                                                        ]}
                                                                    >
                                                                        <InputNumber
                                                                            style={{
                                                                                width: '100%'
                                                                            }}
                                                                            min={
                                                                                0
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        noStyle
                                                                        shouldUpdate={(
                                                                            prevValues,
                                                                            currentValues
                                                                        ) => {
                                                                            return (
                                                                                prevValues
                                                                                    .tickets[
                                                                                    index
                                                                                ]
                                                                                    .unlimited !==
                                                                                currentValues
                                                                                    .tickets[
                                                                                    index
                                                                                ]
                                                                                    .unlimited
                                                                            )
                                                                        }}
                                                                    >
                                                                        {({
                                                                            getFieldValue
                                                                        }) => {
                                                                            const unlimited =
                                                                                getFieldValue(
                                                                                    `tickets`
                                                                                )[
                                                                                    index
                                                                                ]
                                                                                    .unlimited
                                                                            if (
                                                                                unlimited
                                                                            ) {
                                                                                return null
                                                                            }

                                                                            return (
                                                                                <Form.Item
                                                                                    label="Qtd. Máxima"
                                                                                    name={[
                                                                                        field.name,
                                                                                        'max'
                                                                                    ]}
                                                                                >
                                                                                    <InputNumber
                                                                                        style={{
                                                                                            width: '100%'
                                                                                        }}
                                                                                        min={
                                                                                            0
                                                                                        }
                                                                                    />
                                                                                </Form.Item>
                                                                            )
                                                                        }}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            )
                                        })}

                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    add({})
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Adicionar
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card>
                            <Form.Item
                                name="allowCoupon"
                                valuePropName="checked"
                                initialValue={true}
                                noStyle
                            >
                                <Checkbox>Permite cupons</Checkbox>
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card>
                            <Form.Item
                                name="singlePurchase"
                                valuePropName="checked"
                                initialValue={false}
                                noStyle
                            >
                                <Checkbox>Compra única</Checkbox>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </CrudModal>
    )
}

export default TicketOfferModal
