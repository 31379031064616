import { gql } from '@apollo/client'
import { WatchProductData } from '../fragments'

export const CREATE_WATCH_PRODUCT = gql`
    mutation CreateWatchProduct(
        $name: String!
        $description: String
        $permissions: WatchProductPermissionsInput!
        $value: Int
    ) {
        createWatchProduct(
            input: {
                name: $name
                description: $description
                permissions: $permissions
                value: $value
            }
        ) {
            ...WatchProductData
        }
    }
    ${WatchProductData}
`

export const UPDATE_WATCH_PRODUCT = gql`
    mutation UpdateWatchProduct(
        $id: ObjectID!
        $name: String!
        $description: String
        $permissions: WatchProductPermissionsInput!
        $value: Int
    ) {
        updateWatchProduct(
            id: $id
            input: {
                name: $name
                description: $description
                permissions: $permissions
                value: $value
            }
        ) {
            ...WatchProductData
        }
    }
    ${WatchProductData}
`

export const DELETE_WATCH_PRODUCT = gql`
    mutation DeleteWatchProduct($id: ObjectID!) {
        deleteWatchProduct(id: $id) {
            id
            success
        }
    }
`

