export const IMAGE_LABELS = {
    bgImage: {
        label: 'Imagem de fundo desktop',
        layoutTooltip:
            'Selecione "Preencher" para definir o modo de preenchimedo da imagem e a sua posição. Selecione "Repetir" para que a imagem se repita de modo a ocupar todo o espaço de fundo.',
        objectFitTooltop: 'Escolha o tamanho da imagem em relação ao fundo.',
        objectPosition: 'Defina o alinhamento da imagem de fundo.'
    },
    mobileBgImage: {
        label: 'Imagem de fundo mobile',
        layoutTooltip:
            'Selecione "Preencher" para definir o modo de preenchimedo da imagem e a sua posição. Selecione "Repetir" para que a imagem se repita de modo a ocupar todo o espaço de fundo.',
        objectFitTooltop: 'Escolha o tamanho da imagem em relação ao fundo.',
        objectPosition: 'Defina o alinhamento da imagem de fundo.'
    },
    bgVideo: {
        label: 'Vídeo de fundo desktop',
        layoutTooltip:
            'Selecione "Preencher" para definir o modo de preenchimedo do video e a sua posição. Selecione "Repetir" para que o video se repita de modo a ocupar todo o espaço de fundo.',
        objectFitTooltop: 'Escolha o tamanho do video em relação ao fundo.',
        objectPosition: 'Defina o alinhamento do video de fundo.'
    },
    mobileBgVideo: {
        label: 'Vídeo de fundo mobile',
        layoutTooltip:
            'Selecione "Preencher" para definir o modo de preenchimedo do video e a sua posição. Selecione "Repetir" para que o video se repita de modo a ocupar todo o espaço de fundo.',
        objectFitTooltop: 'Escolha o tamanho do video em relação ao fundo.',
        objectPosition: 'Defina o alinhamento do video de fundo.'
    }
}

export const VIDEO_LABELS = {
    bgVideo: {
        label: 'Vídeo de fundo desktop',
        layoutTooltip:
            'Selecione "Preencher" para definir o modo de preenchimedo do video e a sua posição. Selecione "Repetir" para que o video se repita de modo a ocupar todo o espaço de fundo.',
        objectFitTooltop: 'Escolha o tamanho do video em relação ao fundo.',
        objectPosition: 'Defina o alinhamento do video de fundo.'
    },
    mobileBgVideo: {
        label: 'Vídeo de fundo mobile',
        layoutTooltip:
            'Selecione "Preencher" para definir o modo de preenchimedo do video e a sua posição. Selecione "Repetir" para que o video se repita de modo a ocupar todo o espaço de fundo.',
        objectFitTooltop: 'Escolha o tamanho do video em relação ao fundo.',
        objectPosition: 'Defina o alinhamento do video de fundo.'
    }
}

export const OPTIONS_LAYOUT = [
    {
        label: 'Preencher',
        value: 'fill'
    },
    {
        label: 'Repetir',
        value: 'repeat'
    }
]

export const OPTIONS_SIZE = [
    {
        label: 'Preencher',
        value: 'fill'
    },
    {
        label: 'Conter',
        value: 'contain'
    },
    {
        label: 'Capa',
        value: 'cover'
    },
    {
        label: 'Não preencher',
        value: 'none'
    }
]

export const OPTIONS_POSITION = [
    {
        label: 'Centro',
        value: 'center center'
    },
    {
        label: 'Topo',
        value: 'top'
    },
    {
        label: 'Baixo',
        value: 'bottom'
    }
]

export const handleShowMediaSubControls = (name) => {
    return (prevValues, currentValues) => {
        return (
            prevValues.sections[name].configs.container.bgImage?.layout !==
            currentValues.sections[name].configs.container.bgImage?.layout
        )
    }
}
