import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import ChiefUsersModal from './ChiefUserModal'

import { GET_USERS_BY_ROLE } from '@cms/events/graphql/queries/user'
import { CURRENT_USER } from '@cms/core/graphql/queries/auth'
import { DELETE_USER } from '@cms/events/graphql/mutations/user'

import dayjs from 'dayjs'

const path = '/principal/executivos/usuarios'

const ModalRoutes = CreateModalRoutes(ChiefUsersModal, path)

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name'],
        sorter: 'name',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    image={obj.node?.image?.url}
                    title={obj.node.name}
                    text={[obj.node.jobTitle]}
                />
            )
        }
    },
    {
        title: 'E-mail',
        dataIndex: [''],
        render: (_value, obj) => {
            return <CrudColumns onlyText text={[obj.node.email]} />
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const variables = {
    first: 25,
    orderBy: { createdAt: 'DESC' },
    role: ['chief']
}

const TeamList = () => {
    const query = useQuery(GET_USERS_BY_ROLE, {
        variables
    })

    const [deleteData] = useMutation(DELETE_USER, {
        refetchQueries: [{ query: GET_USERS_BY_ROLE, variables }]
    })

    const { data } = useQuery(CURRENT_USER)

    if (query.error) return <div>Error: {query.error}</div>

    const filterFields = [
        { name: 'name', label: 'Nome', queryType: 'Filter' },
        { name: 'email', label: 'E-mail', queryType: 'Filter' }
    ]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Executivos',
                    subTitle: 'Adicione e gerencie usuários executivos',
                    buttons: [
                        {
                            children: 'Novo Executivo',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'users'}
                onDelete={deleteData}
                disableFor={data.me.id}
                filterFields={filterFields}
                showHeader
                {...query}
            />
        </>
    )
}

export default TeamList
