import React from 'react'
import { message, Form, Input, Select, Row, Col } from 'antd'

import { useApolloClient, useQuery } from '@apollo/client'

import IgnorePasswordAutoComplete from '@cms/core/components/IgnorePasswordAutoComplete'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { GET_USER } from '@cms/events/graphql/queries/user'
import {
    CREATE_OR_UPDATE_USER,
    UPDATE_USER
} from '@cms/events/graphql/mutations/user'

import { GET_WATCH_PLANS_LIST_ORDENABLE } from '@cms/watch/graphql/queries/watchPlan'
import { GET_WATCH_USERS_LIST_ORDERABLE } from '@cms/watch/graphql/queries/watchUsers'

const WatchUserModal = ({ ...props }) => {
    const client = useApolloClient()

    const queryPlans = useQuery(GET_WATCH_PLANS_LIST_ORDENABLE, {
        variables: { first: 99999, orderBy: { name: 'ASC' } }
    })
    const watchPlans = queryPlans?.data?.watchPlans?.edges?.map(({ node }) => ({
        ...node
    }))

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_USER,
                variables: { id }
            })

            return { ...data.user, watchPlan: data.user?.watchPlan?.id }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_OR_UPDATE_USER,
                variables: { ...formData, role: 'lead' },
                refetchQueries: [
                    {
                        query: GET_WATCH_USERS_LIST_ORDERABLE,
                        variables: { first: 25, orderBy: { name: 'ASC' } }
                    }
                ]
            })

            message.success('Usuário cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_USER,
                variables: { id, ...formData },
                refetchQueries: [
                    {
                        query: GET_WATCH_USERS_LIST_ORDERABLE,
                        variables: { first: 25, orderBy: { name: 'ASC' } }
                    }
                ]
            })

            message.success('Usuário atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Usuário' : 'Adicionar Usuário'}
            {...modalProps}
            {...props}
            width={650}
        >
            <Form layout={'vertical'} form={form}>
                <IgnorePasswordAutoComplete />

                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'E-mail é obrigatório!'
                                }
                            ]}
                            {...getError('email')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Plano"
                            name="watchPlan"
                            rules={[
                                {
                                    required: true,
                                    message: 'Plano é obrigatório!'
                                }
                            ]}
                            {...getError('watchPlan')}
                        >
                            <Select>
                                {watchPlans?.map(({ id, name }) => (
                                    <Select.Option
                                        key={id}
                                        value={id}
                                        title={name}
                                    >
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    label="Password"
                    name="password"
                    {...getError('password')}
                >
                    <Input.Password placeholder={isEdit ? '**********' : ''} />
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default WatchUserModal

