import React from 'react'

import { useParams } from 'react-router-dom'

import { Card, Col, Layout, Row, Typography } from 'antd'
import { useMutation, useQuery } from '@apollo/client'

import dayjs from 'dayjs'

import { ButtonLink, ButtonReturn } from '@cms/events/components/Buttons'
import Header from '@cms/events/components/Lead/Header'
import { GET_USER } from '@cms/events/graphql/queries/user'

import { GET_USER_WATCH_PRODUCTS_LIST_ORDENABLE } from '@cms/watch/graphql/queries/userWatchProduct'

import Content from '@cms/core/components/Content'
import {
    CreateModalRoutes,
    CrudColumns,
    CrudList
} from '@cms/core/components/Crud'
import Loader from '@cms/core/components/Loader'

import { DELETE_USER_WATCH_PRODUCT } from '@cms/watch/graphql/mutations/userWatchProduct'
import { removeFromList } from '@cms/watch/graphql/utils'

import WatchUserProductModal from '../WatchUserProductModal'

import { ContentContainer } from './styles'
import { useCreation } from '@umijs/hooks'

const { Text, Title } = Typography
const columns = [
    {
        title: 'Produto',
        dataIndex: ['node', 'product', 'name'],
        sorter: 'product.name',
        render: (value, obj) => {
            return <CrudColumns title={value} onlyText />
        }
    },
    {
        title: 'Data de expiração',
        dataIndex: ['node', 'expiresAt'],
        sorter: 'expiresAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

export default function ModalContent({ params }) {
    const { userId } = useParams()

    const queryUserWatchProduct = useQuery(
        GET_USER_WATCH_PRODUCTS_LIST_ORDENABLE,
        {
            variables: {
                user: userId,
                first: 9999,
                orderBy: { createdAt: 'DESC' }
            }
        }
    )

    const { data: dataUser, loading: loadingUser } = useQuery(GET_USER, {
        variables: { id: userId }
    })

    const [deleteUserWachProductData] = useMutation(DELETE_USER_WATCH_PRODUCT, {
        update: removeFromList({
            list: 'userWatchProducts',
            Type: 'UserWatchProduct'
        })
    })

    const path = `/usuarios/${userId}/produtos`
    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(WatchUserProductModal, path, {
            userId
        })
    }, [userId])

    if (queryUserWatchProduct.error) return <div>Error</div>

    if (!dataUser || loadingUser) {
        return <Loader padding="10px" />
    }

    const { user } = dataUser

    return (
        <>
            <ModalRoutes />
            <Layout
                style={{
                    height: '100%',
                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Content
                    style={{
                        padding: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.0)',
                        marginBottom: '0',
                        marginTop: '120px'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px'
                        }}
                    >
                        <Row align="middle" justify="start">
                            <ButtonReturn id="ButtonReturn" path="usuarios" />
                            <Text
                                style={{
                                    marginLeft: '20px',
                                    fontWeight: 'bold'
                                }}
                            >
                                Produtos
                            </Text>
                        </Row>

                        <Card bodyStyle={{ padding: 0 }} bordered={false}>
                            <Header>
                                <Row className="widthFull" align={'middle'}>
                                    <Col span={18}>
                                        <Row>
                                            <CrudColumns
                                                avatar={{
                                                    size: 80
                                                }}
                                                image={user?.avatar?.url}
                                            />
                                            <Col className="infoJob">
                                                <Title
                                                    id="userName"
                                                    style={{ marginTop: 0 }}
                                                >
                                                    {user?.name}
                                                </Title>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row justify={'space-evenly'}>
                                            <ButtonLink
                                                to={path + '/adicionar'}
                                                text={'Adicionar produto'}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </Header>
                        </Card>
                        <ContentContainer>
                            <Card
                                bodyStyle={{
                                    paddingLeft: 0,
                                    paddingRight: '8px',
                                    paddingTop: 0
                                }}
                                bordered={false}
                            >
                                <CrudList
                                    columns={columns}
                                    search={false}
                                    onDelete={deleteUserWachProductData}
                                    queryName={'userWatchProducts'}
                                    {...queryUserWatchProduct}
                                />
                            </Card>
                        </ContentContainer>
                    </div>
                </Content>
            </Layout>
        </>
    )
}

