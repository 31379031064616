import { gql } from '@apollo/client'

export const GET_SALES_BY_TICKET_TYPE_FINANCIAL = gql`
    query GetSalesByTicketType(
        $eventId: ObjectID!
        $startDate: Date!
        $endDate: Date
        $offer: ObjectID
        $status: String
    ) {
        salesByTicketType(
            eventId: $eventId
            startDate: $startDate
            endDate: $endDate
            offer: $offer
            status: $status
        ) {
            ticketType
            count
            percentage
        }
    }
`

export const GET_SALES_BY_PAYMENT_TYPE_FINANCIAL = gql`
    query GetSalesByPaymentType(
        $eventId: ObjectID!
        $startDate: Date!
        $endDate: Date
        $status: String
        $offer: ObjectID
    ) {
        salesByPaymentType(
            eventId: $eventId
            startDate: $startDate
            endDate: $endDate
            status: $status
            offer: $offer
        ) {
            type
            count
            percentage
            total
        }
    }
`

export const GET_SALES_BY_PAYMENT_STATUS_FINANCIAL = gql`
    query GetSalesByPaymentStatus(
        $eventId: ObjectID!
        $startDate: Date!
        $endDate: Date
        $offer: ObjectID
    ) {
        salesByPaymentStatus(
            eventId: $eventId
            startDate: $startDate
            endDate: $endDate
            offer: $offer
        ) {
            status
            value
            percentage
        }
    }
`

export const GET_SALES_DAILY_OVERRAL_PERFORMANCE_FINANCIAL = gql`
    query GetSalesDailyOverralPerformance(
        $eventId: ObjectID!
        $startDate: Date!
        $endDate: Date
        $byHour: Boolean
        $status: String
        $offer: ObjectID
    ) {
        salesDailyOverallPerformance(
            eventId: $eventId
            startDate: $startDate
            endDate: $endDate
            byHour: $byHour
            status: $status
            offer: $offer
        ) {
            date
            paid {
                count
                volume
            }
            pending {
                count
                volume
            }
        }
    }
`

export const GET_SALES_OFFER_OPTIONS = gql`
    query SalesOfferOptions($eventId: ObjectID!) {
        salesOfferOptions(eventId: $eventId) {
            id
            name
        }
    }
`
