import { Row } from 'antd'
import styled from 'styled-components'

export const MoveCarets = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    padding-right: 10px;

    span {
        margin: 0px 3px;
    }
`
