import { gql } from '@apollo/client'
import { WatchProductData } from '../fragments'

export const GET_WATCH_PRODUCTS_LIST_ORDENABLE = gql`
    query GetWatchProducts(
        $first: Int
        $afterCursor: Cursor
        $orderBy: WatchProductOrderBy
        $search: String
        $name: Filter
    ) {
        watchProducts(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: { name: $name }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...WatchProductData
                }
            }
        }
    }

    ${WatchProductData}
`

export const GET_WATCH_PRODUCT = gql`
    query GetWatchProduct($id: ObjectID!) {
        watchProduct(id: $id) {
            ...WatchProductData
        }
    }

    ${WatchProductData}
`

