import React, { useCallback, useEffect } from 'react'

import { Row, Form, Col, Typography, Input, Button } from 'antd'
import { useThemeBuilderContext } from '../../context'
import { MobilePanelContainer, MobilePanelContent } from './styles'
import Card from '@cms/core/components/Card'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'

const { Paragraph } = Typography

const ThemeConfigMobile = () => {
    const [form] = Form.useForm()

    const { mobileConfig, setMobileConfig } = useThemeBuilderContext()

    const updateMobileConfig = useCallback(
        (changedValues, values) => {
            setMobileConfig(values)
        },
        [setMobileConfig]
    )

    useEffect(() => {
        if (mobileConfig) {
            form.setFieldsValue(mobileConfig)
        }
    }, [mobileConfig])

    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col lg={24} sm={24}>
                    <MobilePanelContainer>
                        <Form
                            layout="vertical"
                            form={form}
                            onValuesChange={updateMobileConfig}
                            hideRequiredMark
                        >
                            <MobilePanelContent>
                                <Card title="Onboarding Carrossel">
                                    <Form.List name="onboardingCarousel">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <div
                                                        key={`onboardingCarousel.${index}`}
                                                    >
                                                        <Row
                                                            className="actionsBox"
                                                            gutter={24}
                                                            align="middle"
                                                        >
                                                            <Col span={24}>
                                                                <Paragraph>
                                                                    Slide{' '}
                                                                    {index + 1}
                                                                </Paragraph>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Row
                                                                    gutter={24}
                                                                    align="middle"
                                                                >
                                                                    <Col
                                                                        span={4}
                                                                    >
                                                                        <Form.Item
                                                                            {...field}
                                                                            label="Imagem"
                                                                            name={[
                                                                                field.name,
                                                                                'image'
                                                                            ]}
                                                                        >
                                                                            <MediaUploader
                                                                                type="image"
                                                                                style="image"
                                                                                options={{
                                                                                    optimize:
                                                                                        {
                                                                                            quality: 85
                                                                                        }
                                                                                }}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col
                                                                        span={
                                                                            18
                                                                        }
                                                                    >
                                                                        <Row
                                                                            gutter={
                                                                                24
                                                                            }
                                                                        >
                                                                            <Col
                                                                                span={
                                                                                    24
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    label="Título"
                                                                                    name={[
                                                                                        field.name,
                                                                                        'title'
                                                                                    ]}
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                'Título é obrigatório!'
                                                                                        }
                                                                                    ]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col
                                                                                span={
                                                                                    24
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    label="Descrição"
                                                                                    name={[
                                                                                        field.name,
                                                                                        'description'
                                                                                    ]}
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                'Descrição é obrigatório!'
                                                                                        }
                                                                                    ]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col
                                                                        span={2}
                                                                    >
                                                                        <MinusCircleOutlined
                                                                            style={{
                                                                                marginTop: 20
                                                                            }}
                                                                            onClick={() =>
                                                                                remove(
                                                                                    field.name
                                                                                )
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            add({})
                                                        }}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <PlusOutlined />{' '}
                                                        Adicionar
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Card>
                                <Card title="Menu extra links">
                                    <Form.List name="menuExtraLinks">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <div
                                                        key={`menuExtraLinks.${index}`}
                                                    >
                                                        <Row
                                                            className="actionsBox"
                                                            gutter={24}
                                                            align="middle"
                                                        >
                                                            <Col span={24}>
                                                                <Paragraph>
                                                                    Extra link{' '}
                                                                    {index + 1}
                                                                </Paragraph>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item
                                                                    {...field}
                                                                    label="Label"
                                                                    name={[
                                                                        field.name,
                                                                        'label'
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required:
                                                                                true,
                                                                            message:
                                                                                'Label é obrigatório!'
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={14}>
                                                                <Form.Item
                                                                    {...field}
                                                                    label="Url"
                                                                    name={[
                                                                        field.name,
                                                                        'url'
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required:
                                                                                true,
                                                                            message:
                                                                                'Url é obrigatório!'
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={2}>
                                                                <MinusCircleOutlined
                                                                    style={{
                                                                        marginTop: 20
                                                                    }}
                                                                    onClick={() =>
                                                                        remove(
                                                                            field.name
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            add({})
                                                        }}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <PlusOutlined />{' '}
                                                        Adicionar
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Card>
                            </MobilePanelContent>
                        </Form>
                    </MobilePanelContainer>
                </Col>
            </Row>
        </div>
    )
}

export default ThemeConfigMobile

