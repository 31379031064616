import { FeaturedHero } from './Hero'

export const HeroSections = {
    sectionHero: {
        Component: FeaturedHero,
        name: 'sectionHero',
        category: 'Hero',
        label: 'Hero',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'en-US': 'Be the best, learn from the best.',
                'es-ES': 'Sé el mejor, aprende de los mejores.',
                'pt-PT': 'Sê o melhor, aprendendo com os melhores.',
                'pt-BR': 'Seja o melhor, aprendendo com os melhores.'
            },
            description: {
                'en-US':
                    'Access thousands of content pieces as big as your hunger for knowledge.',
                'es-ES':
                    'Accede a miles de contenidos del tamaño de tu hambre de conocimiento.',
                'pt-PT':
                    'Tenha acesso a milhares de conteúdos do tamanho da sua fome de conhecimento.',
                'pt-BR':
                    'Tenha acesso a milhares de conteúdos do tamanho da sua fome de conhecimento.'
            },
            condition: {
                type: 'AND',
                filters: []
            }
        }
    }
}
