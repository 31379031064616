import React from 'react'
import { Form, Button, Collapse, Input, Space } from 'antd'

import {
    ButtonConfig,
    ColorsBadges,
    ContainerConfig,
    Uploader
} from '../../../components'

import { Wrapper, Title } from '../../../styles'
import { DeleteOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons'
import RichTextEditor from '../../../components/RichTextEditor/Editor'
import { nanoid } from 'nanoid'
const { Panel } = Collapse

export interface ICardsProps {
    name: string
}

export default function Cards({ name }: ICardsProps) {
    const form = Form.useFormInstance()
    return (
        <>
            <ContainerConfig name={name} />

            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Cartões</Title>

                <Form.List name={[name, 'configs', 'cards']}>
                    {(fields, { add, remove }) => (
                        <>
                            <Collapse>
                                {fields.map(
                                    ({ key, name: _name, ...restField }, i) => {
                                        const currentFieldValue =
                                            form.getFieldValue([
                                                'sections',
                                                name,
                                                'configs',
                                                'cards',
                                                _name
                                            ])

                                        return (
                                            <Panel
                                                header={i + 1 + 'º Cartão'}
                                                key={key}
                                                extra={
                                                    <Space>
                                                        <CopyOutlined
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                add({
                                                                    ...currentFieldValue,
                                                                    id: nanoid()
                                                                })
                                                            }}
                                                        />
                                                        <DeleteOutlined
                                                            onClick={() =>
                                                                remove(_name)
                                                            }
                                                        />
                                                    </Space>
                                                }
                                            >
                                                <Form.Item
                                                    name={[_name, 'customId']}
                                                    label="ID"
                                                >
                                                    <Input />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[_name, 'bgColor']}
                                                    label="Cor do cartão"
                                                >
                                                    <ColorsBadges />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[
                                                        _name,
                                                        'image',
                                                        'src'
                                                    ]}
                                                    label="Imagem"
                                                >
                                                    <Uploader
                                                        value={undefined}
                                                        onChange={undefined}
                                                        options={undefined}
                                                    />
                                                </Form.Item>

                                                <Wrapper>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            _name,
                                                            'topTag',
                                                            'text'
                                                        ]}
                                                        label="Tag topo"
                                                    >
                                                        <RichTextEditor size="small" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            _name,
                                                            'topTag',
                                                            'bgColor'
                                                        ]}
                                                        label="Cor da tag"
                                                    >
                                                        <ColorsBadges />
                                                    </Form.Item>
                                                </Wrapper>

                                                <Form.Item
                                                    {...restField}
                                                    name={[
                                                        _name,
                                                        'title',
                                                        'text'
                                                    ]}
                                                    label="Título"
                                                >
                                                    <RichTextEditor />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[
                                                        _name,
                                                        'price',
                                                        'text'
                                                    ]}
                                                    label="Preço"
                                                >
                                                    <RichTextEditor />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[
                                                        _name,
                                                        'discountPrice',
                                                        'text'
                                                    ]}
                                                    label="Preço com desconto"
                                                >
                                                    <RichTextEditor size="small" />
                                                </Form.Item>

                                                <Wrapper>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            _name,
                                                            'discountTag',
                                                            'text'
                                                        ]}
                                                        label="Tag de desconto"
                                                    >
                                                        <RichTextEditor />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            _name,
                                                            'discountTag',
                                                            'bgColor'
                                                        ]}
                                                        label="Cor da tag"
                                                    >
                                                        <ColorsBadges />
                                                    </Form.Item>
                                                </Wrapper>

                                                <Form.Item
                                                    {...restField}
                                                    name={[
                                                        _name,
                                                        'small',
                                                        'text'
                                                    ]}
                                                    label="Pequeno texto"
                                                >
                                                    <RichTextEditor />
                                                </Form.Item>

                                                <ButtonConfig
                                                    enableActions
                                                    allowVisibilityControl
                                                    fieldPath={[
                                                        _name,
                                                        'button'
                                                    ]}
                                                    rootPath={[
                                                        'sections',
                                                        name,
                                                        'configs',
                                                        'cards'
                                                    ]}
                                                />

                                                <Form.Item
                                                    {...restField}
                                                    name={[
                                                        _name,
                                                        'features',
                                                        'text'
                                                    ]}
                                                    label="Detalhes"
                                                >
                                                    <RichTextEditor />
                                                </Form.Item>
                                            </Panel>
                                        )
                                    }
                                )}
                            </Collapse>

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() =>
                                        add({
                                            id: nanoid(),
                                            button: {
                                                active: false
                                            }
                                        })
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Adicionar cartão
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Botões expandir</Title>
                <Form.Item
                    name={[name, 'configs', 'expand', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[name, 'configs', 'expand', 'more']}
                    label="Texto botão 'ver mais'"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={[name, 'configs', 'expand', 'less']}
                    label="Texto botão 'ver menos'"
                >
                    <Input />
                </Form.Item>

                <ButtonConfig
                    title="Botões"
                    enableActions={false}
                    inner
                    enableText={false}
                    fieldPath={[name, 'configs', 'expand']}
                />

                <Form.Item
                    name={[name, 'configs', 'arrows', 'textColor']}
                    label="Cor das setas de navegação"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
        </>
    )
}
