import React from 'react'
import { Form, Select, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import {
    IMAGE_LABELS,
    handleShowMediaSubControls,
    OPTIONS_LAYOUT,
    OPTIONS_POSITION,
    OPTIONS_SIZE
} from './constants'
import Uploader from '@cms/core/Uploader'
import { Title, Wrapper } from '../../styles'

export function ImageConfig(props) {
    const { name, type } = props

    return (
        <>
            <Wrapper>
                <Title level={4}>Configurações de imagem de fundo</Title>
                <Form.Item
                    name={[name, 'configs', 'container', type, 'src']}
                    label={IMAGE_LABELS[type].label}
                >
                    <Uploader />
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        const src = getFieldValue([
                            'sections',
                            name,
                            'configs',
                            'container',
                            type,
                            'src'
                        ])

                        console.log(
                            'sections',
                            name,
                            'configs',
                            'container',
                            type,
                            'src'
                        )

                        return src ? (
                            <>
                                <Form.Item
                                    name={[
                                        name,
                                        'configs',
                                        'container',
                                        type,
                                        'layout'
                                    ]}
                                    label={
                                        <Tooltip
                                            title={
                                                IMAGE_LABELS[type].layoutTooltip
                                            }
                                        >
                                            Preenchimento <InfoCircleOutlined />
                                        </Tooltip>
                                    }
                                    rules={[{ required: true }]}
                                    initialValue={'fill'}
                                >
                                    <Select options={OPTIONS_LAYOUT} />
                                </Form.Item>

                                <Form.Item
                                    noStyle
                                    shouldUpdate={handleShowMediaSubControls(
                                        name
                                    )}
                                >
                                    {({ getFieldValue }) => {
                                        const layout = getFieldValue([
                                            'sections',
                                            name,
                                            'configs',
                                            'container',
                                            type,
                                            'layout'
                                        ])
                                        return layout !== 'repeat' ? (
                                            <>
                                                <Form.Item
                                                    name={[
                                                        name,
                                                        'configs',
                                                        'container',
                                                        type,
                                                        'objectFit'
                                                    ]}
                                                    label={
                                                        <Tooltip
                                                            title={
                                                                IMAGE_LABELS[
                                                                    type
                                                                ]
                                                                    .objectFitTooltip
                                                            }
                                                        >
                                                            Tamanho{' '}
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    }
                                                    rules={[
                                                        {
                                                            required: true
                                                        }
                                                    ]}
                                                    initialValue={'cover'}
                                                >
                                                    <Select
                                                        options={OPTIONS_SIZE}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[
                                                        name,
                                                        'configs',
                                                        'container',
                                                        type,
                                                        'objectPosition'
                                                    ]}
                                                    label={
                                                        <Tooltip
                                                            title={
                                                                IMAGE_LABELS[
                                                                    type
                                                                ]
                                                                    .objectPositionTooltip
                                                            }
                                                        >
                                                            Posição{' '}
                                                            <InfoCircleOutlined />
                                                        </Tooltip>
                                                    }
                                                    rules={[
                                                        {
                                                            required: true
                                                        }
                                                    ]}
                                                    initialValue={
                                                        'center center'
                                                    }
                                                >
                                                    <Select
                                                        options={
                                                            OPTIONS_POSITION
                                                        }
                                                    />
                                                </Form.Item>
                                            </>
                                        ) : null
                                    }}
                                </Form.Item>
                            </>
                        ) : null
                    }}
                </Form.Item>
            </Wrapper>
        </>
    )
}
