import React, { useEffect, useRef } from 'react'
import {
    Form,
    Select,
    Typography,
    Row,
    Col,
    Empty,
    Popconfirm,
    Collapse,
    Button
} from 'antd'
import WebFont from 'webfontloader'

// import Colors from './Colors'
import Colors from './Colors'
import FontSelector from './FontSelector'
import { Uploader, PanelFooter, Preview } from '../../components'
import { usePageBuilderContext } from '../../context/context'
import { ColForm, Wrapper } from './styles'

import { COLORS } from '../../constants/labels'
import AddCustomColor from '../../components/AddCustomColor'
import {
    ConsoleSqlOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons'
import { nanoid } from 'nanoid'
import Affix from '@cms/core/components/Affix'
import { googleFonts } from '@cms/core/themes/Fonts'

const { Option } = Select
const { Title } = Typography
const { Panel } = Collapse
const General = (props) => {
    const [form] = Form.useForm()
    const { site, colors } = usePageBuilderContext()
    const colRef = useRef(null)
    const previewRef = useRef(null)

    WebFont.load({
        classes: false,
        events: false,
        google: {
            families: googleFonts.map((font) => font)
        }
    })

    const updateSection = () => {
        const data = form.getFieldsValue()
        const _data = {
            ...site,
            theme: { ...data },
            sections: site.pages.home.sections
        }

        if (_data && previewRef.current) {
            previewRef.current.Emit('fullPage', _data)
        }
    }

    useEffect(() => {
        form.setFieldsValue(site.theme)
    }, [site])

    if (!site) return null

    const onInit = () => {
        previewRef.current.Emit('fullPage', {
            ...site,
            sections: site.pages.home.sections
        })
        previewRef.current.handleParentWidth(colRef.current.offsetWidth)
        previewRef.current.handleParentHeight(600)
    }

    return (
        <Row gutter={24} style={{ padding: '0px 12px' }}>
            <ColForm md={12} sm={24}>
                <Form
                    name="theme"
                    form={form}
                    onValuesChange={updateSection}
                    layout="vertical"
                >
                    <Form.Item
                        label="Favicon (.png, .jpg)"
                        name={'favicon'}
                        className="favUploader"
                    >
                        <Uploader />
                    </Form.Item>
                    <Form.Item label="Box" name={['box', 'type']}>
                        <Select>
                            <Option value="circle">Redondo</Option>
                            <Option value="rounded">Arredondado</Option>
                            <Option value="default">Quadrado</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Botão" name={['button', 'type']}>
                        <Select>
                            <Option value="circle">Redondo</Option>
                            <Option value="rounded">Arredondado</Option>
                            <Option value="default">Quadrado</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Fonte principal" name={['font']}>
                        <FontSelector />
                    </Form.Item>

                    <Title className="list-label" level={4}>
                        Fontes personalizadas
                    </Title>
                    <Wrapper>
                        <Form.List name={['customFonts']}>
                            {(fields, { add, remove }) => (
                                <>
                                    <Collapse>
                                        {fields.map(
                                            (
                                                { key, name, ...restField },
                                                i
                                            ) => (
                                                <Panel
                                                    header={'Fonte ' + (i + 1)}
                                                    key={key}
                                                    extra={
                                                        <Popconfirm
                                                            title="Você tem certeza que quer remover esta fonte personalizada?"
                                                            onConfirm={() =>
                                                                remove(i)
                                                            }
                                                            okText="Sim"
                                                            cancelText="Não"
                                                        >
                                                            <DeleteOutlined />
                                                        </Popconfirm>
                                                    }
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'font']}
                                                        label="Fonte personalizada"
                                                    >
                                                        <FontSelector />
                                                    </Form.Item>
                                                </Panel>
                                            )
                                        )}
                                    </Collapse>
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() =>
                                                add({
                                                    font: 'Roboto',
                                                    name:
                                                        'CustomFont-' + nanoid()
                                                })
                                            }
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Adicionar fonte
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Wrapper>
                    <Title className="list-label" level={4}>
                        Cores
                    </Title>
                    {Object.entries(colors).map(([key, color]) => {
                        return (
                            <Wrapper key={key}>
                                <Form.Item
                                    name={['colors', key]}
                                    label={COLORS[key]}
                                    key={key}
                                >
                                    <Colors />
                                </Form.Item>
                            </Wrapper>
                        )
                    })}

                    <Title className="list-label" level={4}>
                        Cores Personalizadas
                    </Title>

                    <Form.List name={['customColors']}>
                        {(fields, { add, remove }) => {
                            return (
                                <>
                                    {fields.length === 0 && (
                                        <Wrapper>
                                            <Empty
                                                description={
                                                    <span>
                                                        <a>
                                                            Nenhuma cor
                                                            personalizada foi
                                                            adicionada
                                                        </a>
                                                    </span>
                                                }
                                            >
                                                <AddCustomColor
                                                    index={1}
                                                    add={add}
                                                    emptyCustomColor
                                                    form={form}
                                                ></AddCustomColor>
                                            </Empty>
                                        </Wrapper>
                                    )}

                                    {fields.map((field, key) => {
                                        const _fields = form.getFieldsValue()
                                        return (
                                            <Wrapper key={field.key}>
                                                <Row justify="end">
                                                    <Col>
                                                        <Popconfirm
                                                            title="Você tem certeza que quer remover esta cor personalizada?"
                                                            onConfirm={() =>
                                                                remove(key)
                                                            }
                                                            okText="Sim"
                                                            cancelText="Não"
                                                        >
                                                            <DeleteOutlined />
                                                        </Popconfirm>
                                                    </Col>
                                                </Row>
                                                <Form.Item
                                                    label={
                                                        'Cor personalizada ' +
                                                        (key + 1)
                                                    }
                                                    name={[field.name, 'color']}
                                                >
                                                    <Colors />
                                                </Form.Item>
                                            </Wrapper>
                                        )
                                    })}
                                    {fields.length > 0 && (
                                        <AddCustomColor
                                            add={add}
                                            index={fields.length + 1}
                                            form={form}
                                        ></AddCustomColor>
                                    )}
                                </>
                            )
                        }}
                    </Form.List>
                    <PanelFooter />
                </Form>
            </ColForm>
            <Col md={12} sm={24} ref={colRef}>
                <Affix offsetTop={104}>
                    <Preview ref={previewRef} onInit={onInit} page="home" />
                </Affix>
            </Col>
        </Row>
    )
}

export default General
