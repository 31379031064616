import React, { useRef, useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col, Typography, Space, Button } from 'antd'
import { useQuery, useMutation } from '@apollo/client'
import { EditOutlined } from '@ant-design/icons'

import { UPDATE_SITE } from '@cms/events/graphql/mutations/site'
import { GET_EVENT_CHECKOUT } from '@cms/events/graphql/queries/event'
import { ButtonLink } from '@cms/events/components/Buttons'

import { templates } from '../../templates'
import PreviewModal from './PreviewModal'
import { Box, OverlayStyled, Wrapper } from './styles'
import { usePageBuilderContext } from '../../context/context'
import { parseDate } from '../../utils'
import Loader from '@cms/core/components/Loader'

const { Title } = Typography

const TemplateSelector = () => {
    const { eventId } = useParams()
    const [previewData, setPreviewData] = useState(false)
    const history = useHistory()
    const [updateSite] = useMutation(UPDATE_SITE)

    const { setSite, getSession } = usePageBuilderContext()

    const {
        error,
        data: dataEvent,
        loading
    } = useQuery(GET_EVENT_CHECKOUT, {
        variables: { id: eventId },
        fetchPolicy: 'network-only'
    })

    const handlePublishedSite = async (template) => {
        const { id } = dataEvent.event.checkout

        await updateSite({
            variables: {
                id,
                draft: template,
                session: getSession(id)
            }
        })
        setSite(null)

        history.push(`/evento/${eventId}/config/checkout`)
    }

    const handlePreview = (data) => {
        setPreviewData(data)
    }

    const Overlay = ({ template, name, data }) => {
        return (
            <OverlayStyled>
                <Space direction="vertical">
                    <Title level={3}>{name}</Title>
                    <ButtonLink
                        icon={<EditOutlined />}
                        onClick={() => handlePublishedSite(template)}
                        text="Começar a editar"
                    />
                    <Button type="primary" onClick={() => handlePreview(data)}>
                        Visualizar
                    </Button>
                </Space>
            </OverlayStyled>
        )
    }

    if (loading) return <Loader fixed={false} padding="10px" />

    return (
        <Wrapper>
            <Row justify="center">
                <Title>Selecione um template</Title>
            </Row>
            <Row gutter={20} justify="center">
                {templates.map((template) => {
                    const _template = template(dataEvent.event)
                    const {
                        layout,
                        info: { name, image }
                    } = _template
                    const data = {
                        ..._template,
                        sections: []
                    }

                    return (
                        <Col lg={8} xs={24} key={layout}>
                            <Box>
                                <img src={image} />
                                <Overlay
                                    name={name}
                                    template={_template}
                                    data={data}
                                />
                            </Box>
                        </Col>
                    )
                })}
                {previewData && (
                    <PreviewModal
                        data={previewData}
                        setPreviewData={setPreviewData}
                    />
                )}
            </Row>
        </Wrapper>
    )
}

export default TemplateSelector
