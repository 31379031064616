import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const UPDATE_WATCH_CONFIG = gql`
    mutation CreateOrUpdateWatchConfig(
        $domain: DomainInputs
        $scripts: ScriptsInputs
        $theme: JSON
        $home: HomeInput
        $translation: TranslateInput
        $loginBackgroundImage: MediaInput
        $footerConfig: FooterConfigInput
        $menus: WatchMenusInput
    ) {
        createOrUpdateWatchConfig(
            input: {
                domain: $domain
                theme: $theme
                scripts: $scripts
                home: $home
                translation: $translation
                loginBackgroundImage: $loginBackgroundImage
                footerConfig: $footerConfig
                menus: $menus
            }
        ) {
            ...WatchConfigData
        }
    }

    ${fragments.WatchConfigData}
`

