import React from 'react'
import { Row } from 'antd'

import {
    DashboardTitle,
    IconWrapper
} from '@cms/events/components/Dashboard/styles'

import MainNumber from './MainNumber'
import MainNumberV2 from './MainNumberV2'
import CompareCircle from './CompareCircle'
import CompareLines from './CompareLines'

import { StyledTDDashboardCard } from './styles'

const DashboardCard = ({ children, titleSmall, ...props }) => {
    const { title, subTitle, options, icon: Icon } = props
    return (
        <StyledTDDashboardCard>
            <Row style={{ marginBottom: '30px' }} justify="space-between">
                <Row align="middle">
                    <IconWrapper>
                        <Icon style={{ color: '#fff' }} />
                    </IconWrapper>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <DashboardTitle titleSmall={titleSmall}>
                            {title}
                        </DashboardTitle>
                        <span
                            style={{ fontSize: '0.75rem', marginLeft: '10px' }}
                        >
                            {subTitle}
                        </span>
                    </div>
                </Row>
                {options}
            </Row>
            {children}
        </StyledTDDashboardCard>
    )
}

export default DashboardCard
export { MainNumber, CompareCircle, CompareLines, MainNumberV2 }

