export const SectionsType = {
    categories: 'categories',
    content: 'content',
    headerMenu: 'headerMenu',
    header: 'header',
    experts: 'experts',
    video: 'video',
    courses: 'courses'
}

export const SECTIONS_MOCK = {
    sections: [
        {
            name: 'Header',
            type: SectionsType.header,
            active: true,
            config: {
                title: {
                    'en-US': 'Be the best, learn from the best.',
                    'es-ES': 'Sé el mejor, aprende de los mejores.',
                    'pt-PT': 'Sê o melhor, aprendendo com os melhores.',
                    'pt-BR': 'Seja o melhor, aprendendo com os melhores.'
                },
                description: {
                    'en-US':
                        'Access thousands of content pieces as big as your hunger for knowledge.',
                    'es-ES':
                        'Accede a miles de contenidos del tamaño de tu hambre de conocimiento.',
                    'pt-PT':
                        'Tenha acesso a milhares de conteúdos do tamanho da sua fome de conhecimento.',
                    'pt-BR':
                        'Tenha acesso a milhares de conteúdos do tamanho da sua fome de conhecimento.'
                }
            }
        },
        {
            name: 'Categorias',
            type: SectionsType.categories,
            active: true,
            config: {
                title: {
                    'pt-PT': 'Categorias',
                    'pt-BR': 'Categorias',
                    'en-US': 'Categories',
                    'es-ES': 'Categorias'
                },
                categories: ''
            }
        },
        {
            name: 'Conteúdos',
            type: 'content',
            active: true,
            config: {
                variant: 'primary',
                title: {
                    'en-US': 'Most viewed content.',
                    'es-ES': 'Contenidos más vistos.',
                    'pt-PT': 'Conteúdos mais vistos.',
                    'pt-BR': 'Conteúdos mais vistos.'
                },
                condition: {
                    type: 'AND',
                    filters: []
                }
            }
        },
        {
            name: 'Vídeo',
            type: 'video',
            active: true,
            config: {
                title: {
                    'pt-PT': 'Assista de qualquer lugar',
                    'pt-BR': 'Assista de qualquer lugar',
                    'en-US': 'Watch from anywhere',
                    'es-ES': 'Mira desde cualquier lugar'
                },
                description: {
                    'pt-PT': 'Comece a assistir no telemóvel ou no computador.',
                    'pt-BR': 'Comece a assistir no celular ou no computador.',
                    'en-US': 'Start watching on your phone or computer.',
                    'es-ES': 'Empieza a verlo en tu teléfono o computadora.'
                },
                video: ''
            }
        },
        {
            name: 'Especialistas',
            type: SectionsType.experts,
            active: true,
            config: {
                title: {
                    'pt-PT':
                        'Especialistas que já passaram pelos nossos conteúdos.',
                    'es-ES':
                        'Especialistas que ya han pasado por nuestros contenidos.',
                    'en-US': 'Experts who have gone through our content.',
                    'pt-BR':
                        'Especialistas que já passaram pelos nossos conteúdos.'
                },
                experts: []
            }
        }
    ]
}

