export const systemFonts = [
    'Arial',
    'Arial Black',
    'Comic Sans MS',
    'Impact',
    'Times New Roman',
    'Verdana'
]

export const googleFonts = [
    'Anton SC',
    'Archivo',
    'Barlow Condensed',
    'Chakra Petch',
    'Figtree',
    'Golos Text',
    'Host Grotesk',
    'Inter',
    'Jura',
    'Lato',
    'Merriweather',
    'Montserrat',
    'Noto Sans JP',
    'Open Sans',
    'Open Sans Condensed',
    'Oswald',
    'Poppins',
    'PT Sans',
    'Quicksand',
    'Raleway',
    'Roboto',
    'Roboto Condensed',
    'Roboto Mono',
    'Roboto Slab',
    'Sora',
    'Source Sans Pro',
    'Space Grotesk',
    'Space Mono',
    'Syne',
    'Teko',
    'Trirong',
    'Ubuntu'
]
