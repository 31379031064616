import styled, { css } from 'styled-components'
import { getTextColor } from '../../utils'

import svg from '@cms/core/assets/media/database.svg'
import { FormatEditorCustomProp } from './utils'
// const highlightColor = '#7730f6'
const highlightColor = '#ffffff'
const clearColor = '#787b7d'

export const TextEditor = styled.div`
    margin: 0 auto;

    .ql-editor {
        min-height: 100px;
    }

    .ql-container {
        min-height: 10em;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        background: #fefcfc90;
        color: #000;
        border: 2px solid rgba(228, 236, 255, 0.1);
        ${({ font }) => css`
            font-family: ${font} !important;
        `};
    }

    .ql-color-picker {
        .ql-picker-item {
            border: 1px solid #000;
            &:first-child {
                background-color: transparent !important;
                display: flex;
                justify-content: center;
                align-items: center;

                &::before {
                    content: '\\1F6AB';
                }
            }
        }
    }

    ${({ colors }) =>
        Object.keys(colors).map(
            (color) => css`
                .ql-picker-label.ql-active[data-value='${color}'] * {
                    stroke: ${getTextColor(colors[color])} !important;
                }

                .ql-color-picker .ql-picker-item[data-value='${color}'] {
                    background-color: ${getTextColor(colors[color])} !important;
                }

                .td-color-${color} {
                    color: ${getTextColor(colors[color])} !important;
                }
            `
        )}

    ${({ customColors }) =>
        customColors?.map(
            ({ name, color }) => css`
                .ql-picker-label.ql-active[data-value='${name.replace(
                        / /g,
                        '-'
                    )}']
                    * {
                    stroke: ${getTextColor(color)} !important;
                }

                .ql-color-picker
                    .ql-picker-item[data-value='${name.replace(/ /g, '-')}'] {
                    background-color: ${getTextColor(color)} !important;
                }

                .td-color-${name.replace(/ /g, '-')} {
                    color: ${getTextColor(color)} !important;
                }
            `
        )}

    ${({ customColors }) =>
        customColors?.map(
            ({ name, color }) => css`
                .ql-picker-label.ql-active[data-value='${FormatEditorCustomProp(
                        name
                    )}']
                    * {
                    stroke: ${getTextColor(color)} !important;
                }

                .ql-color-picker
                    .ql-picker-item[data-value='${FormatEditorCustomProp(
                        name
                    )}'] {
                    background-color: ${getTextColor(color)} !important;
                }

                .td-color-${FormatEditorCustomProp(name)} {
                    color: ${getTextColor(color)} !important;
                }
            `
        )}

    ${({ customFonts }) =>
        customFonts?.map(
            ({ name, font }) => css`
                .td-font-${name} {
                    font-family: ${font} !important;
                }
            `
        )}
        
    ${({ customFonts }) =>
        customFonts?.map(
            ({ name, font }) => css`
                .td-font-${FormatEditorCustomProp(name)} {
                    font-family: ${font} !important;
                }
            `
        )}

    .quill {
        border: 2px solid rgb(82 84 89);
        border-top: 0px;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
    }

    .ql-expanded {
        .ql-picker-label {
            border-color: transparent !important;
        }
    }

    .ql-picker-options {
        border-color: #4f5155 !important;
        border-radius: 0.5em !important;
        background: #424346 !important;
    }

    .ql-snow {
        .ql-toolbar {
            button:focus,
            button.ql-active,
            .ql-picker-label:hover,
            .ql-picker-label.ql-active,
            .ql-picker-item:hover,
            .ql-picker-item.ql-selected {
                color: ${highlightColor};
            }

            button:hover .ql-fill,
            button:focus .ql-fill,
            button.ql-active .ql-fill,
            .ql-picker-label:hover .ql-fill,
            .ql-picker-label.ql-active .ql-fill,
            .ql-picker-item:hover .ql-fill,
            .ql-picker-item.ql-selected .ql-fill,
            button:hover .ql-stroke.ql-fill,
            button:focus .ql-stroke.ql-fill,
            button.ql-active .ql-stroke.ql-fill,
            .ql-picker-label:hover .ql-stroke.ql-fill,
            .ql-picker-label.ql-active .ql-stroke.ql-fill,
            .ql-picker-item:hover .ql-stroke.ql-fill,
            .ql-picker-item.ql-selected .ql-stroke.ql-fill {
                fill: ${highlightColor};
            }

            button:hover .ql-stroke,
            button:focus .ql-stroke,
            button.ql-active .ql-stroke,
            .ql-picker-label:hover .ql-stroke,
            .ql-picker-label.ql-active .ql-stroke,
            .ql-picker-item:hover .ql-stroke,
            .ql-picker-item.ql-selected .ql-stroke,
            button:hover .ql-stroke-miter,
            button:focus .ql-stroke-miter,
            button.ql-active .ql-stroke-miter,
            .ql-picker-label:hover .ql-stroke-miter,
            .ql-picker-label.ql-active .ql-stroke-miter,
            .ql-picker-item:hover .ql-stroke-miter,
            .ql-picker-item.ql-selected .ql-stroke-miter {
                stroke: ${highlightColor};
            }

            button.ant-switch {
                background: rgba(255, 255, 255, 0.25);
                border: unset;
                cursor: unset;
                display: unset;
                float: unset;
                height: unset;
                padding: unset;
                width: unset;
                &:hover {
                    cursor: pointer;
                }
            }
            button.ant-switch-checked {
                background: #7850dc;
            }
        }
        &.ql-toolbar {
            background: #424346;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
            border-bottom: none;
            border: 2px solid rgb(82 84 89);

            button:hover,
            button:focus,
            button.ql-active,
            .ql-picker-label:hover,
            .ql-picker-label.ql-active,
            .ql-picker-item:hover,
            .ql-picker-item.ql-selected {
                color: ${highlightColor};
            }

            button:hover .ql-fill,
            button:focus .ql-fill,
            button.ql-active .ql-fill,
            .ql-picker-label:hover .ql-fill,
            .ql-picker-label.ql-active .ql-fill,
            .ql-picker-item:hover .ql-fill,
            .ql-picker-item.ql-selected .ql-fill,
            button:hover .ql-stroke.ql-fill,
            button:focus .ql-stroke.ql-fill,
            button.ql-active .ql-stroke.ql-fill,
            .ql-picker-label:hover .ql-stroke.ql-fill,
            .ql-picker-label.ql-active .ql-stroke.ql-fill,
            .ql-picker-item:hover .ql-stroke.ql-fill,
            .ql-picker-item.ql-selected .ql-stroke.ql-fill {
                fill: ${highlightColor};
            }

            button:hover .ql-stroke,
            button:focus .ql-stroke,
            button.ql-active .ql-stroke,
            .ql-picker-label:hover .ql-stroke,
            .ql-picker-label.ql-active .ql-stroke,
            .ql-picker-item:hover .ql-stroke,
            .ql-picker-item.ql-selected .ql-stroke,
            button:hover .ql-stroke-miter,
            button:focus .ql-stroke-miter,
            button.ql-active .ql-stroke-miter,
            .ql-picker-label:hover .ql-stroke-miter,
            .ql-picker-label.ql-active .ql-stroke-miter,
            .ql-picker-item:hover .ql-stroke-miter,
            .ql-picker-item.ql-selected .ql-stroke-miter {
                stroke: ${highlightColor};
            }

            button.ant-switch {
                background: rgba(255, 255, 255, 0.25);
                border: unset;
                cursor: unset;
                display: unset;
                float: unset;
                height: unset;
                padding: unset;
                width: unset;
                &:hover {
                    cursor: pointer;
                }
            }
            button.ant-switch-checked {
                background: #7850dc;
            }
            .ql-bold,
            .ql-italic,
            .ql-underline,
            .ql-strike {
                .ql-stroke {
                    stroke: ${clearColor};
                }
            }

            .ql-picker-label {
                border-color: transparent;
            }
        }

        .ql-stroke {
            stroke: ${clearColor};
        }
        .ql-fill {
            fill: ${clearColor};
        }
    }

    .td-align-center {
        text-align: center;
    }

    .td-align-left {
        text-align: left;
    }

    .td-align-right {
        text-align: right;
    }

    .td-align-justify {
        text-align: justify;
    }

    .ql-picker {
        color: ${clearColor};
        &.ql-td-font {
            .ql-picker-label {
                padding-right: 24px;
                &::before {
                    content: attr(data-label);
                }
            }
            .ql-picker-item:before {
                content: attr(data-label);
            }
        }
    }

    .ql-picker.ql-modelFields {
        width: 30px;
        .ql-picker-label::before {
            content: url(${svg});
            display: block;
            width: 19px;
            transform: translate(-11px, 3px);
        }
        .ql-picker-item::before {
            content: attr(data-label);
        }
    }
`
