import React from 'react'
import { Form, Button, Collapse, Space, Checkbox, Alert, Row } from 'antd'

import {
    ButtonConfig,
    ColorsBadges,
    ContainerConfig,
    Uploader
} from '../../../components'

import { Wrapper, Title } from '../../../styles'
import {
    CaretUpOutlined,
    CaretDownOutlined,
    DeleteOutlined,
    PlusOutlined,
    CopyOutlined
} from '@ant-design/icons'
import RichTextEditor from '../../../components/RichTextEditor/Editor'
import { nanoid } from 'nanoid'
import { MoveCarets } from './styles'
const { Panel } = Collapse

export interface ICardsProps {
    name: string
}

const PanelHeader = ({ index, title, onMoveUp, onMoveDown, fieldsLength }) => (
    <Row justify="start" align="middle">
        <MoveCarets>
            {index !== 0 && <CaretUpOutlined onClick={onMoveUp} />}
            {index !== fieldsLength - 1 && (
                <CaretDownOutlined onClick={onMoveDown} />
            )}
        </MoveCarets>
        <Title level={4}>{title}</Title>
    </Row>
)

const GetTextFromHtml = (text: string) => {
    return text?.replace(/<[^>]+>/g, '') || ''
}

export default function CardsWithTable({ name }: ICardsProps) {
    const form = Form.useFormInstance()
    return (
        <>
            <ContainerConfig name={name} />

            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Pequeno texto abaixo da tabela</Title>
                <Form.Item
                    name={[name, 'configs', 'small', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Collapse>
                <Panel header="Tipos de ingresso" key="1">
                    <Form.List name={[name, 'configs', 'ticketTypes']}>
                        {(fields, { add, remove, move }) => (
                            <>
                                <Collapse>
                                    {fields.map(
                                        (
                                            { key, name: _name, ...restField },
                                            i
                                        ) => {
                                            const currentFieldValue =
                                                form.getFieldValue([
                                                    'sections',
                                                    name,
                                                    'configs',
                                                    'ticketTypes',
                                                    _name
                                                ])

                                            return (
                                                <Panel
                                                    showArrow={false}
                                                    header={
                                                        <PanelHeader
                                                            index={i}
                                                            fieldsLength={
                                                                fields.length
                                                            }
                                                            title={
                                                                i +
                                                                1 +
                                                                'º ' +
                                                                GetTextFromHtml(
                                                                    currentFieldValue
                                                                        ?.title
                                                                        ?.text
                                                                )
                                                            }
                                                            onMoveUp={(e) => {
                                                                e.stopPropagation()
                                                                move(i, i - 1)
                                                            }}
                                                            onMoveDown={(e) => {
                                                                e.stopPropagation()
                                                                move(i, i + 1)
                                                            }}
                                                        />
                                                    }
                                                    key={key}
                                                    extra={
                                                        <Space>
                                                            <CopyOutlined
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation()
                                                                    add({
                                                                        ...currentFieldValue,
                                                                        id: nanoid()
                                                                    })
                                                                }}
                                                            />
                                                            <DeleteOutlined
                                                                onClick={() =>
                                                                    remove(
                                                                        _name
                                                                    )
                                                                }
                                                            />
                                                        </Space>
                                                    }
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            _name,
                                                            'bgColor'
                                                        ]}
                                                        label="Cor do ingresso"
                                                    >
                                                        <ColorsBadges />
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            _name,
                                                            'title',
                                                            'text'
                                                        ]}
                                                        label="Título"
                                                    >
                                                        <RichTextEditor />
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            _name,
                                                            'price',
                                                            'text'
                                                        ]}
                                                        label="Preço"
                                                    >
                                                        <RichTextEditor />
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            _name,
                                                            'discountPrice',
                                                            'text'
                                                        ]}
                                                        label="Preço com desconto"
                                                    >
                                                        <RichTextEditor size="small" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            _name,
                                                            'small',
                                                            'text'
                                                        ]}
                                                        label="Pequeno texto"
                                                    >
                                                        <RichTextEditor />
                                                    </Form.Item>

                                                    <ButtonConfig
                                                        enableActions
                                                        allowVisibilityControl
                                                        fieldPath={[
                                                            _name,
                                                            'button'
                                                        ]}
                                                        rootPath={[
                                                            'sections',
                                                            name,
                                                            'configs',
                                                            'ticketTypes'
                                                        ]}
                                                    />
                                                </Panel>
                                            )
                                        }
                                    )}
                                </Collapse>

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() =>
                                            add({
                                                id: nanoid(),
                                                button: {
                                                    active: false
                                                }
                                            })
                                        }
                                        block
                                        icon={<PlusOutlined />}
                                        disabled={fields.length === 3}
                                    >
                                        Adicionar ingresso
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Panel>

                <Panel header="Características" key="2">
                    <Form.List name={[name, 'configs', 'features']}>
                        {(fields, { add, remove, move }) => (
                            <>
                                <Collapse>
                                    {fields.map(
                                        (
                                            { key, name: _name, ...restField },
                                            i
                                        ) => {
                                            const currentFieldValue =
                                                form.getFieldValue([
                                                    'sections',
                                                    name,
                                                    'configs',
                                                    'features',
                                                    _name
                                                ])

                                            return (
                                                <Panel
                                                    showArrow={false}
                                                    header={
                                                        <PanelHeader
                                                            index={i}
                                                            fieldsLength={
                                                                fields.length
                                                            }
                                                            title={
                                                                i +
                                                                1 +
                                                                'º ' +
                                                                GetTextFromHtml(
                                                                    currentFieldValue
                                                                        ?.title
                                                                        ?.text
                                                                )
                                                            }
                                                            onMoveUp={(e) => {
                                                                e.stopPropagation()
                                                                move(i, i - 1)
                                                            }}
                                                            onMoveDown={(e) => {
                                                                e.stopPropagation()
                                                                move(i, i + 1)
                                                            }}
                                                        />
                                                    }
                                                    key={key}
                                                    extra={
                                                        <Space>
                                                            <CopyOutlined
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation()
                                                                    add({
                                                                        ...currentFieldValue,
                                                                        id: nanoid()
                                                                    })
                                                                }}
                                                            />
                                                            <DeleteOutlined
                                                                onClick={() =>
                                                                    remove(
                                                                        _name
                                                                    )
                                                                }
                                                            />
                                                        </Space>
                                                    }
                                                >
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate
                                                    >
                                                        {({
                                                            getFieldValue
                                                        }) => {
                                                            const currentTicketTypes =
                                                                getFieldValue([
                                                                    'sections',
                                                                    name,
                                                                    'configs',
                                                                    'ticketTypes'
                                                                ])

                                                            const ticketTypes =
                                                                []
                                                            currentTicketTypes?.map(
                                                                (
                                                                    ticketType
                                                                ) => {
                                                                    ticketTypes.push(
                                                                        {
                                                                            label:
                                                                                GetTextFromHtml(
                                                                                    ticketType
                                                                                        ?.title
                                                                                        ?.text
                                                                                ) ||
                                                                                'Ingresso sem nome',
                                                                            value: ticketType?.id
                                                                        }
                                                                    )
                                                                }
                                                            )

                                                            if (
                                                                ticketTypes.length ===
                                                                0
                                                            ) {
                                                                return (
                                                                    <Alert
                                                                        message={`Cadastre um tipo de ingresso para adicionar características.`}
                                                                        type="warning"
                                                                    />
                                                                )
                                                            }

                                                            return (
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[
                                                                        _name,
                                                                        'tickets'
                                                                    ]}
                                                                    label="Faz parte de quais ingressos?"
                                                                >
                                                                    <Checkbox.Group
                                                                        options={
                                                                            ticketTypes
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            )
                                                        }}
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            _name,
                                                            'title',
                                                            'text'
                                                        ]}
                                                        label="Título"
                                                    >
                                                        <RichTextEditor />
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            _name,
                                                            'description',
                                                            'text'
                                                        ]}
                                                        label="Descrição"
                                                    >
                                                        <RichTextEditor />
                                                    </Form.Item>
                                                </Panel>
                                            )
                                        }
                                    )}
                                </Collapse>

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() =>
                                            add({
                                                id: nanoid()
                                            })
                                        }
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Adicionar característica
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Panel>

                <Panel header="Estilos" key="3">
                    <Wrapper>
                        <Title level={4}>Icones da tabela</Title>
                        <Form.Item
                            name={[name, 'configs', 'trueIcon', 'src']}
                            label="Icone para sinalizar que está incluso"
                        >
                            <Uploader
                                value={undefined}
                                onChange={undefined}
                                options={undefined}
                            />
                        </Form.Item>
                        <Form.Item
                            name={[name, 'configs', 'falseIcon', 'src']}
                            label="Icone para sinalizar que não está incluso"
                        >
                            <Uploader
                                value={undefined}
                                onChange={undefined}
                                options={undefined}
                            />
                        </Form.Item>
                    </Wrapper>

                    <Wrapper>
                        <Title level={4}>Estilos da tabela</Title>
                        <Form.Item
                            name={[name, 'configs', 'table', 'bgColor']}
                            label="Cor do zebrado da tabela"
                        >
                            <ColorsBadges />
                        </Form.Item>
                    </Wrapper>
                </Panel>
            </Collapse>
        </>
    )
}
