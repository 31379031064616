import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

// import 'antd/dist/antd.css'

import Content from '@cms/core/components/Content'
import { Row } from 'antd'

export const CardTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
`

export interface ICrudContainerStyleProps {
    borderless: boolean
    empty: boolean
}

export const CrudContainer = styled.div<ICrudContainerStyleProps>`
    max-width: 100%;
    border-radius: 10px;
    height: 100%;

    /* ${({ borderless }) =>
        borderless &&
        css`
            .ant-table-tbody > tr:nth-child(2) > td,
            .ant-table-tbody > tr > td:first-child > div.crud-information,
            td {
                border: none !important;
            }
        `} */

    &.container-transparent {
        /* background: transparent;
        background-color: transparent; */
    }

    .ant-page-header {
        margin-bottom: 16px;
        padding: 0;

        span.ant-page-header-heading-extra {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }

    /* .input-search {
        background: transparent;
    } */
    .ant-table-wrapper
        .ant-table-ping-right:not(.ant-table-has-fix-right)
        .ant-table-container::after {
        box-shadow: inset -10px 0 8px -8px rgba(10, 10, 10, 0.37);
        border-radius: 8px;
    }

    .ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
        /* background: #373740; */
        border-radius: 0;
    }

    .ant-table-wrapper
        .ant-table-container
        table
        > thead
        > tr:first-child
        > *:first-child {
        border-start-start-radius: 0;
    }

    .ant-table-wrapper
        .ant-table-container
        table
        > thead
        > tr:first-child
        > :first-child {
        border-start-start-radius: 0px;
    }

    .ant-table-wrapper .ant-table .ant-table-header {
        border-radius: 0;
    }

    .ant-table-wrapper .ant-table-container {
        border-start-start-radius: 0;
        border-start-end-radius: 0;
    }

    .ant-table-wrapper td.ant-table-column-sort {
        background: transparent;
    }

    .ant-table-wrapper
        .ant-table-ping-right
        .ant-table-cell-fix-right-first::after,
    :where(.css-1bj8byr).ant-table-wrapper
        .ant-table-ping-right
        .ant-table-cell-fix-right-last::after {
        box-shadow: inset -10px 0 8px -8px rgba(10, 10, 10, 0.37);
    }

    .ant-table-wrapper
        .ant-table-ping-left
        .ant-table-cell-fix-left-first::after,
    .ant-table-wrapper
        .ant-table-ping-left
        .ant-table-cell-fix-left-last::after {
        box-shadow: inset 8px 0px 10px -8px rgba(10, 10, 10, 0.37);
    }

    .ant-table-column-sorter-inner > span {
        font-size: 13px;
    }

    .ant-table-body {
        overflow-y: auto !important;
    }

    .ant-table-body > div {
        ${(props) =>
            props.empty
                ? `
            height: 165px !important;
            max-height: 165px !important;
            overflow-y: hidden;
        `
                : null}
    }

    .actions-extra {
        padding-right: 5px;
    }
    /* .actions-main {
        padding-left: 0px;
    } */

    .crudlist-end {
        position: absolute;
        bottom: 0px;
    }

    .queryBuilder {
        select,
        .ruleGroup-addRule {
            /* color: #000;
            border: none;
            background: #fff; */
            height: 40px;
            padding: 8px 15px;
            font-size: 14px;
            border-radius: 10px;
            margin-right: 10px;
            /* text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
            box-shadow: 0 2px 0 rgb(0 0 0 / 5%); */
            cursor: pointer;
        }
        .ruleGroup-addGroup {
            display: none;
        }
    }
`
export const ButtonLink = styled(Link)`
    /* color: #fff;
    background: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); */
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 2px;

    &:focus,
    &:hover {
        /* color: #fff;
        background: #40a9ff;
        border-color: #40a9ff; */
    }
`

export const CrudListContainer = styled.div<{ $height?: string }>`
    display: flex;
    flex-direction: column;
    height: ${({ $height }) => $height || 'calc(100vh - 124px)'};
    max-width: 100%;

    ${CrudContainer} {
        > .ant-card {
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .ant-card-head {
                position: sticky;
            }

            .ant-card-body {
                flex: 1;
                overflow: hidden;
                .ant-table-wrapper {
                    height: 100%;

                    .ant-spin-nested-loading {
                        height: 100%;

                        .ant-spin-container {
                            height: 100%;

                            .ant-table {
                                height: 100%;

                                .ant-table-container {
                                    height: 100%;
                                    overflow: hidden;
                                    display: flex;
                                    flex-direction: column;

                                    .ant-table-body {
                                        display: flex;
                                        overflow: hidden;
                                        height: 100%;

                                        > div {
                                            flex: 1;
                                            /* max-height: 100% !important;
                                            height: 100% !important; */
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const CrudLisSearchRow = styled.div`
    > .ant-input-search {
        .ant-input-wrapper {
            > .ant-input-affix-wrapper {
                border-radius: 6px;
                background: transparent;

                input {
                    background: transparent;
                }

                &:hover,
                .ant-input-affix-wrapper-focused {
                    background: ${(props) => props.theme.color.backgroundInput};
                }
            }
            .ant-input-group-addon {
                display: none;
            }
        }
    }
`
