import { gql } from '@apollo/client'
import { CustomerData } from '@cms/core/graphql/fragments'

export const GET_CUSTOMER_EVENT = gql`
    query GetCustomerEvent($id: ObjectID!) {
        customer(id: $id) {
            paymentGateway
            splitPercentage
            splitRecipient
            ...CustomerData
        }
    }
    ${CustomerData}
`
