import styled, { css } from 'styled-components'
import { Row as RowAnt, Typography, Tabs } from 'antd'
import { transparentize } from 'polished'
const { Text, Title } = Typography

export const Page = styled.div`
    position: fixed;
    top: 70px;
    right: 0;
    bottom: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    width: ${(props) =>
        props.collapsed
            ? `calc(100% - 80px)!important`
            : `calc(100% - 260px)!important`};
    height: 100vh;
    background-color: #f0f2f5;
    transition: width 0.5s;
`

export const Row = styled(RowAnt)`
    h4 {
        font-size: 16px;
        padding: 10px 0;
    }
`

export const BigCount = styled(Title)`
    &.ant-typography {
        font-size: 32px;
        margin-top: 0;

        @media (max-width: 1400px) {
            font-size: 20px;
        }
    }
`

export const TextSub = styled(Text)`
    &.ant-typography {
        font-size: 16px;
        padding-top: 10px;
        white-space: nowrap;
        overflow: hidden;
    }
`

export const Wrapper = styled.div`
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: 1400px) {
        font-size: 15px;
    }
`

export const ContainerChart = styled.div`
    .recharts-cartesian-axis-tick-value {
        fill: rgba(255, 255, 255, 0.5);
        font-size: 11px;
    }

    .recharts-tooltip-label {
        color: #000;
    }

    .recharts-line-curve {
        stroke-linecap: round;
    }
`

export const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: ${({ theme }) =>
        transparentize(0.9, theme.color.primaryColor)};
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 1.5em;
    }
`

export const DashboardTitle = styled(Text)`
    color: #fff;
    font-weight: bold;
    font-size: 1.4em;
    margin-left: 10px;

    ${({ titleSmall }) =>
        titleSmall &&
        css`
            font-size: 1em;
        `}
`

export const DashboardCardTitle = styled(Row)`
    margin: 0px 0px 16px 0px;
    align-items: center;
`

export const DashboardTabs = styled(Tabs)`
    //max-height: 95vh;
    .ant-tabs-extra-content {
        margin: 0 10px 3px 0;
    }

    @media (max-width: 576px) {
        max-height: 100%;
    }
`
