import React from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'

import { Form } from '../../../styles'
import { PageHeader } from '@ant-design/pro-layout'
import { message } from 'antd'
import { Button, Col, Row } from 'antd'

import Content from '@cms/core/components/Content'

import { useCrudForm } from '@cms/core/components/Crud'
import { UPDATE_EVENT } from '@cms/events/graphql/mutations/event'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { useApolloClient } from '@apollo/client'

import EventInfo from '@cms/events/components/Event/EventInfo'
import Loader from '@cms/core/components/Loader'

const InfoScreen = () => {
    const { eventId } = useParams()
    const client = useApolloClient()

    const [{ form, formSave, saveLoading, dataLoading, formUpdate, getError }] =
        useCrudForm(
            {
                relateds: [],
                id: eventId,
                getData: async (id) => {
                    if (!id) return
                    const response = await client.query({
                        query: GET_EVENT,
                        variables: { id }
                    })

                    const data = { ...response.data.event }

                    if (data.startDate)
                        data.startDate = dayjs(new Date(data.startDate))
                    if (data.endDate)
                        data.endDate = dayjs(new Date(data.endDate))

                    return data
                },
                updateData: async (id, formData) => {
                    const variables = { id, ...formData }

                    await client.mutate({
                        mutation: UPDATE_EVENT,
                        variables
                    })

                    message.success('Informaçoes atualizadas com sucesso!')
                }
            },
            [eventId]
        )

    if (dataLoading) return <Loader fixed={false} padding="10px" />

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Informações do Evento"
                subTitle="Configuração de informações básicas do evento"
            />
            <Content className="container-light">
                
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={formSave}
                    onValuesChange={formUpdate}
                >
                    <EventInfo form={form} getError={getError} edit />

                    <Row style={{ marginTop: 20 }} className="footer">
                        <Col span={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </>
    )
}

export default InfoScreen
