import React, { createContext, useCallback, useContext, useState } from 'react'
import { useEffect } from 'react'
import WebFont from 'webfontloader'
import models from '../constants/models'
import { useEventEmitter } from '@umijs/hooks'
import { nanoid } from 'nanoid'
import { UPDATE_SITE } from '@cms/events/graphql/mutations/site'

import { useMutation } from '@apollo/client'

export const PageBuilderContext = createContext({
    colors: {},
    setColors: () => null,
    customFonts: [],
    setCustomFonts: () => [],
    customColors: [],
    setCustomColors: () => null,
    loading: false,
    setLoading: () => null,
    site: {},
    setSite: () => null,
    config: {},
    setConfig: () => null,
    event: {},
    setEvent: () => null,
    sectionsModalParams: null,
    setSectionsModalParams: () => null,
    preview: null,
    setPreview: () => null,
    page: null,
    setPage: () => null,
    model: null,
    setModel: () => null,
    fieldRefValue: null,
    setFieldRefValue: () => null,
    formSubmit$: null,
    getSession: () => null,
    version: 0,
    setVersion: () => null,
    updateSite: () => null
})

export const usePageBuilderContext = () => useContext(PageBuilderContext)

export const PageBuilderProvider = ({ children }) => {
    const [colors, setColors] = useState(null)
    const [customFonts, setCustomFonts] = useState([])
    const [customColors, setCustomColors] = useState(null)
    const [loading, setLoading] = useState(false)
    const [site, setSite] = useState(null)
    const [config, setConfig] = useState(null)
    const [event, setEvent] = useState(null)
    const [sectionsModalParams, setSectionsModalParams] = useState(null)
    const [preview, setPreview] = useState(null)
    const [page, setPage] = useState(null)
    const [model, setModel] = useState(null)
    const [fieldRefValue, setFieldRefValue] = useState(null)
    const [version, setVersion] = useState(0)
    const [updateSiteData] = useMutation(UPDATE_SITE)

    const formSubmit$ = useEventEmitter()
    const getSession = useCallback((id) => {
        let session = sessionStorage.getItem('site-session-' + id)

        if (!session) {
            session = nanoid()
            sessionStorage.setItem('site-session-' + id, session)
        }

        return session
    }, [])

    useEffect(() => {
        if (page && site?.pages[page]) {
            const pageData = site.pages[page]
            if (pageData.model) {
                setModel({
                    ...pageData.model,
                    ...models[pageData.model.name]
                })
            } else {
                setModel(null)
            }
        }
    }, [page])

    useEffect(() => {
        if (customFonts.length) {
            WebFont.load({
                classes: false,
                events: false,
                google: {
                    families: customFonts.map(({ font }) => {
                        return (
                            font +
                            ':ital,wght@0,400;0,500;0,600;1,400;1,500;1,600'
                        )
                    })
                }
            })
        }
    }, [customFonts])

    useEffect(() => {
        if (site?.theme.font) {
            WebFont.load({
                classes: false,
                events: false,
                google: {
                    families: [
                        site.theme.font +
                            ':ital,wght@0,400;0,500;0,600;1,400;1,500;1,600'
                    ]
                }
            })
        }
    }, [site?.theme.font])

    const updateSite = useCallback(
        async ({ variables }) => {
            const newVersion = (version || 0) + 1
            setVersion(newVersion)

            if (variables.draft) {
                variables.draft.version = newVersion
            }

            variables.session = getSession(variables.id)

            const result = await updateSiteData({
                variables
            })

            setVersion(
                result.data?.updateSites?.draft?.version > newVersion
                    ? result.data?.updateSites?.draft?.version
                    : newVersion
            )

            return result
        },
        [updateSiteData, version]
    )

    return (
        <PageBuilderContext.Provider
            value={{
                colors,
                setColors,
                customFonts,
                setCustomFonts,
                customColors,
                setCustomColors,
                site,
                setSite,
                config,
                setConfig,
                loading,
                setLoading,
                event,
                setEvent,
                sectionsModalParams,
                setSectionsModalParams,
                preview,
                setPreview,
                model,
                setModel,
                page,
                setPage,
                fieldRefValue,
                setFieldRefValue,
                formSubmit$,
                getSession,
                version,
                setVersion,
                updateSite
            }}
        >
            {children}
        </PageBuilderContext.Provider>
    )
}
