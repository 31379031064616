import React, { createContext, useCallback, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import { GET_EVENT } from '@cms/events/graphql/queries/event'

import { UPDATE_EVENT } from '@cms/events/graphql/mutations/event'

import { removeObjectProps } from '@cms/events/utils'
import { useThemeBuilderContext } from '@cms/core/components/ThemeBuilder'

export const MobileContext = createContext({
    mobileConfig: {},
    setMobileConfig: () => null,
    save: () => null
})

export const useMobileContext = () => useContext(MobileContext)

export const MobileProvider = ({ children }) => {
    const { eventId } = useParams()

    const { data, loading } = useQuery(GET_EVENT, {
        variables: { id: eventId }
    })

    const [updateEvent] = useMutation(UPDATE_EVENT)

    const { mobileConfig, setMobileConfig } = useThemeBuilderContext()

    useEffect(() => {
        if (data?.event) {
            const event = removeObjectProps({ ...data.event }, ['__typename'])

            const { mobileConfig } = event

            setMobileConfig(mobileConfig)
        }
    }, [data])

    const save = useCallback(async () => {
        await updateEvent({
            variables: {
                id: eventId,
                mobileConfig
            }
        })
    }, [eventId, mobileConfig])

    return (
        <MobileContext.Provider
            value={{
                mobileConfig,
                setMobileConfig,
                save,
                loading
            }}
        >
            {children}
        </MobileContext.Provider>
    )
}

