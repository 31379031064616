import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Form, message } from 'antd'

import { useQuery, useMutation } from '@apollo/client'

import { GET_EVENT_SITE } from '@cms/events/graphql/queries/event'
import {
    UPDATE_SITE,
    UPDATE_SITE_PRESENCE
} from '@cms/events/graphql/mutations/site'

import { Nav } from '@cms/events/pages/Config/PageBuilder/components'
import { usePageBuilderContext } from '@cms/events/pages/Config/PageBuilder/context/context'
import { PageBuilderRoutes } from '@cms/events/pages/Config/PageBuilder/routes'
import { set, get } from 'lodash'

import Loader from '@cms/core/components/Loader'
import { PresenceModal } from './components/PresenceModal'

const PageBuilder = () => {
    const { eventId } = useParams()
    const { push } = useHistory()
    const {
        setColors,
        setCustomFonts,
        setCustomColors,
        setSite,
        site,
        setConfig,
        config,
        loading,
        setLoading,
        event,
        setEvent,
        preview,
        formSubmit$,
        getSession,
        version,
        setVersion,
        updateSite
    } = usePageBuilderContext()
    const [updateSitePresence] = useMutation(UPDATE_SITE_PRESENCE)
    const [presence, setPresence] = useState(null)

    const [presenceLoading, setPresenceLoading] = useState(false)

    const { error, data: dataEvent } = useQuery(GET_EVENT_SITE, {
        variables: { id: eventId },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        console.log('config', config)
        if (config?.id && !presence) {
            const updateLastSeen = async () => {
                const session = getSession(config.id)
                const result = await updateSitePresence({
                    variables: {
                        id: config.id,
                        session,
                        override: false
                    }
                })

                const { presence, success } = result.data.updateSitePresence

                if (!success) {
                    setPresence(presence)
                } else {
                    setPresence(null)
                }
            }

            updateLastSeen()

            const timer = setInterval(updateLastSeen, 1000 * 30) // 1 minute

            return () => clearInterval(timer)
        }
    }, [config?.id, getSession, presence])

    useEffect(() => {
        if (dataEvent) {
            if (!event) {
                setEvent(dataEvent.event)
            }

            if (!site) {
                const { draft } = dataEvent.event.site

                if (draft) {
                    setCustomFonts(draft.theme.customFonts || [])
                    setColors(draft.theme.colors)
                    setCustomColors(draft.theme.customColors)
                    setVersion(draft.version || 1)
                    setSite(draft)
                    setConfig(dataEvent.event.site)
                } else push(`/evento/${eventId}/paginas/templates`)
            }
        }
    }, [dataEvent])

    if (!dataEvent) return <Loader fixed={false} padding="10px" />
    if (error) return `Error! ${error.message}`

    const onFormFinish = async (name, { values, forms }) => {
        const currentValues = forms[name].getFieldsValue()
        const { id } = config
        let fields = {}
        setLoading(true)

        try {
            if (currentValues?.sections) {
                fields = {
                    ...site,
                    pages: {
                        ...site.pages,
                        [name]: {
                            ...site.pages[name],
                            sections: currentValues.sections
                        }
                    }
                }
            } else {
                if (currentValues?.colors) setColors(currentValues.colors)
                if (currentValues?.customFonts)
                    setCustomFonts(currentValues.customFonts)

                if (currentValues?.customColors)
                    setCustomColors(currentValues.customColors)

                fields = set({ ...site }, name, {
                    ...get(site, name),
                    ...currentValues
                })
            }

            setSite(fields)

            await updateSite({
                variables: {
                    id: id,
                    draft: fields
                }
            })

            formSubmit$.emit()

            message.success('Configurações atualizadas com sucesso!')

            if (preview) {
                preview.refresh()
            }
        } catch (error) {
            console.dir(error)

            if (error?.graphQLErrors?.length) {
                message.warning(error.message)
            } else {
                message.warning(
                    'Não foi possível salvar suas alterações, verifique a conexão de internet e tente novamente.'
                )
            }
        }

        setLoading(false)
    }

    return (
        <>
            {loading && <Loader  padding="10px" />}
            {site ? (
                <>
                    <Nav />
                    {presence && (
                        <PresenceModal
                            presence={presence}
                            loading={presenceLoading}
                            onContinue={() => {
                                setPresenceLoading(true)
                                updateSitePresence({
                                    variables: {
                                        id: config.id,
                                        session: getSession(config.id),
                                        override: true
                                    }
                                })
                                    .then((result) => {
                                        result.data.updateSitePresence
                                            .success && window.location.reload()
                                    })
                                    .catch(() => {
                                        setPresenceLoading(false)
                                    })
                            }}
                        />
                    )}
                    <Form.Provider onFormFinish={onFormFinish}>
                        <PageBuilderRoutes />
                    </Form.Provider>
                </>
            ) : null}
        </>
    )
}

export default PageBuilder
