import React, { useState } from 'react'
import {
    message,
    Form,
    Input,
    Row,
    Col,
    Select,
    Switch,
    InputNumber,
    Checkbox,
    Typography,
    Button,
    Popover,
    Tag,
    Alert
} from 'antd'
import {
    MinusCircleOutlined,
    PlusOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons'
import { useApolloClient, useQuery } from '@apollo/client'

import SelectSearch from '@cms/core/components/SelectSearch'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { CurrencyInput } from '@cms/events/components/CurrencyInput'
import { currencies } from '@cms/events/components/CurrencyInput/currencies'

import { addToList } from '@cms/core/graphql/utils'
import { GET_TICKET } from '@cms/events/graphql/queries/tickets'

import {
    CREATE_TICKET,
    UPDATE_TICKET
} from '@cms/events/graphql/mutations/tickets'

import Card from '@cms/core/components/Card'

import TextArea from 'antd/lib/input/TextArea'
import IntegrationInput from '@cms/core/components/Integrations/components/Input'
import { DeprecatedPermissionInputs } from './deprecatedPermissionInputs'
import { formatCurrency } from '@cms/events/components/CurrencyInput/format-currency'
import { GET_EVENT_CONTENTS_TAGS } from '@cms/events/graphql/queries/event'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'

const { Paragraph, Text } = Typography

const formatPermission = (perm) => {
    if (!perm) {
        return null
    }

    const talks = perm.talks?.map((o) => o.id) || []
    const categories = perm.categories?.map((o) => o.id) || []
    const speakers = perm.speakers?.map((o) => o.id) || []
    const collectionCategories =
        perm.collectionCategories?.map((o) => o.id) || []

    if (
        !perm.talks.length &&
        !perm.categories.length &&
        !perm.speakers.length &&
        !perm.collectionCategories.length
    ) {
        return null
    }

    return {
        talks,
        categories,
        speakers,
        collectionCategories
    }
}

const formatData = ({ ...data }) => {
    if (!data.value) {
        data.value = 0
    }

    if (typeof data.value === 'string') {
        data.value = parseInt(data.value.replace(',', '').replace('.', ''))
    }

    if (!data.quantity) {
        data.quantity = 0
    }

    if (data.benefits) {
        data.benefits = data.benefits.map((obj) => obj.name)
    }

    return data
}

const ticketModules = [
    {
        value: 'NETWORKING',
        label: 'Networking'
    },
    {
        value: 'ONDEMAND',
        label: 'On Demand'
    },
    {
        value: 'CERTIFICATE',
        label: 'Certificado'
    },
    {
        value: 'GUEST_LIST',
        label: 'Lista de convidados'
    },
    {
        value: 'MOBILE_APP',
        label: 'Aplicativo Mobile'
    }
]

const TicketModal = ({ params, ...props }) => {
    const { eventId } = params

    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const response = await client.query({
                query: GET_TICKET,
                variables: { id, eventId }
            })

            const data = { ...response.data.ticket }

            data._accessRestrictions = { ...data.accessRestrictions }
            data.accessRestrictions = formatPermission(data.accessRestrictions)

            data.integrations = data.integrations?.map((o) => o.id) || []

            data.benefits = data.benefits.map((obj) => ({ name: obj }))
            return data
        },
        createData: async (formData) => {
            const data = formatData(formData)

            await client.mutate({
                mutation: CREATE_TICKET,
                update: addToList({ list: 'tickets', Type: 'Ticket' }),
                variables: { ...data, event: eventId, version: 2 }
            })

            message.success('Tipo de ingresso cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            const data = formatData(formData)

            await client.mutate({
                mutation: UPDATE_TICKET,
                variables: { id, ...data, event: eventId }
            })

            message.success('Tipo de ingresso atualizado com sucesso!')
        }
    })

    // const price = Form.useWatch(['value'], form)

    // const showPopOver = (
    //     <div style={{ width: '200px' }}>
    //         <p style={{ display: 'flex', justifyContent: 'space-between' }}>
    //             <span>Valor do ingresso</span>
    //             <span>R$ {formatCurrency(price)}</span>
    //         </p>
    //         <p style={{ display: 'flex', justifyContent: 'space-between' }}>
    //             <span>Taxa</span>
    //             <span>R$ 0</span>
    //         </p>
    //         <hr />
    //         <p style={{ display: 'flex', justifyContent: 'space-between' }}>
    //             <span>
    //                 <strong>Valor do ingresso</strong>
    //             </span>
    //             <span>
    //                 <strong>R$ {formatCurrency(price)}</strong>
    //             </span>
    //         </p>
    //     </div>
    // )

    const { data: TagsData } = useQuery(GET_EVENT_CONTENTS_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    const currency = Form.useWatch(['currency'], form)

    return (
        <CrudModal
            width={820}
            title={isEdit ? 'Editar Ingresso' : 'Novo Ingresso'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Row gutter={24}>
                    <Col span={10}>
                        <Card title="Geral">
                            <Form.Item
                                label="Nome do ingresso"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nome é obrigatório!'
                                    }
                                ]}
                                {...getError('name')}
                            >
                                <Input />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={14}>
                        <Card title="Valor">
                            <Row gutter={24} align={'middle'}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Moeda"
                                        name="currency"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Moeda é obrigatória!'
                                            }
                                        ]}
                                        {...getError('currency')}
                                        initialValue={currencies[0].symbol}
                                    >
                                        <Select>
                                            {currencies.map((item, key) => {
                                                return (
                                                    <Select.Option
                                                        key={key}
                                                        value={item.symbol}
                                                        title={item.name}
                                                    >
                                                        {item.name} (
                                                        {item.symbol})
                                                    </Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Preço" name="value">
                                        <CurrencyInput currency={currency} />
                                    </Form.Item>
                                </Col>
                                {/* <Col span={9}>
                                    <span>Total cobrado</span>
                                    <Col>
                                        <span
                                            style={{
                                                marginRight: '15px'
                                            }}
                                        >
                                            R$ {formatCurrency(price)}
                                        </span>
                                        <span>
                                            <Popover content={showPopOver}>
                                                <QuestionCircleOutlined />
                                            </Popover>
                                        </span>
                                    </Col>
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Card>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Row style={{ alignItems: 'baseline' }}>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked"
                                    initialValue={true}
                                >
                                    <Switch />
                                </Form.Item>
                                <Text strong style={{ marginLeft: '8px' }}>
                                    Ativo
                                </Text>
                            </Row>
                        </Col>

                        <Col span={10}>
                            <Row style={{ alignItems: 'baseline' }}>
                                <Form.Item
                                    name="hideInMainCheckout"
                                    valuePropName="checked"
                                    initialValue={false}
                                >
                                    <Switch />
                                </Form.Item>
                                <Text strong style={{ marginLeft: '8px' }}>
                                    Não exibir no 'checkout principal'
                                </Text>
                            </Row>
                        </Col>

                        <Col span={10}>
                            <Row style={{ alignItems: 'baseline' }}>
                                <Form.Item
                                    name="hideInMyTickets"
                                    valuePropName="checked"
                                    initialValue={false}
                                >
                                    <Switch />
                                </Form.Item>
                                <Text strong style={{ marginLeft: '8px' }}>
                                    Não exibir em 'meus ingressos'
                                </Text>
                            </Row>
                        </Col>
                    </Row>
                </Card>

                <Col span={24}>
                    <Card title="Descrição">
                        <Form.Item
                            label="Descrição do ingresso"
                            name="description"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Descrição é obrigatória!'
                            //     }
                            // ]}
                            initialValue={''}
                            {...getError('description')}
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Card>
                </Col>

                {/* <Col span={24}>
                    <Card>
                        <Form.Item
                            label="Máximo de ingressos por compra"
                            name="maxPerPurchase"
                            {...getError('maxPerPurchase')}
                        >
                            <InputNumber placeholder="Ex: 5" />
                        </Form.Item>
                    </Card>
                </Col> */}

                <Card title="Ocultar conteúdos (Palestras e Palestrantes)">
                    <Col span={24}>
                        <Form.Item label="Tags" name={['contentHiddenByTags']}>
                            <Select
                                mode="tags"
                                tagRender={({ label, ...props }) => (
                                    <Tag {...props} color="#7F44FF">
                                        {label}
                                    </Tag>
                                )}
                                style={{ width: '100%' }}
                                placeholder="Selecione ou adicione tags"
                            >
                                {TagsData?.eventContentsTags?.map((tag) => (
                                    <Select.Option key={tag} value={tag}>
                                        {tag}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Card>

                <Card title="Bloquear conteúdos (Palestras)">
                    <Col span={24}>
                        <Form.Item label="Tags" name={['contentBlockedByTags']}>
                            <Select
                                mode="tags"
                                tagRender={({ label, ...props }) => (
                                    <Tag {...props} color="#7F44FF">
                                        {label}
                                    </Tag>
                                )}
                                style={{ width: '100%' }}
                                placeholder="Selecione ou adicione tags"
                            >
                                {TagsData?.eventContentsTags?.map((tag) => (
                                    <Select.Option key={tag} value={tag}>
                                        {tag}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Imagem de bloqueio dos conteúdos (900x500)"
                            name={['contentBlockedConfig', 'image']}
                            {...getError('contentBlockedConfig.image')}
                        >
                            <MediaUploader
                                options={{
                                    optimize: {
                                        resize: {
                                            width: 900,
                                            height: 500
                                        },
                                        quality: 80
                                    }
                                }}
                                type="image"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Link de bloqueio dos conteúdos"
                            name={['contentBlockedConfig', 'link']}
                            {...getError('contentBlockedConfig.link')}
                        >
                            <Input placeholder="Ex: https://transformacaogitial.com.br/upgrade" />
                        </Form.Item>
                    </Col>
                </Card>

                <Form.Item noStyle shouldUpdate>
                    {() => {
                        const hasLegacy = form.getFieldValue([
                            'accessRestrictions'
                        ])

                        if (!hasLegacy) {
                            return null
                        }

                        return (
                            <Card title="Restringir (legacy)">
                                <Col span={24}>
                                    <Alert
                                        type="warning"
                                        message="Este recurso será removido em futuras atualizações. Utilize os novos campos de ocultar e bloquear conteúdos por tag."
                                    />
                                    <DeprecatedPermissionInputs
                                        eventId={eventId}
                                        getError={getError}
                                    />
                                </Col>
                            </Card>
                        )
                    }}
                </Form.Item>

                <Card title="Benefícios">
                    <Form.List name="benefits">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`benefits.${index}`}>
                                        <Row
                                            className="actionsBox"
                                            gutter={24}
                                            align="middle"
                                        >
                                            <Col span={22}>
                                                <Paragraph>
                                                    Benefício {index + 1}
                                                </Paragraph>
                                                <Form.Item
                                                    label="Descrição"
                                                    name={[field.name, 'name']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: `Benefício ${
                                                                index + 1
                                                            } é obrigatório!`
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    style={{ marginTop: 20 }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add({})
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <PlusOutlined /> Adicionar
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>

                <Card title="Módulos">
                    <Form.Item name="modules">
                        <Checkbox.Group options={ticketModules} />
                    </Form.Item>
                </Card>

                <Card title="Integrations">
                    <IntegrationInput eventId={eventId} />
                </Card>
            </Form>
        </CrudModal>
    )
}

export default TicketModal
