import React, { useState } from 'react'
import { useLocation, Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Affix, Col, Button, Row, Space, message } from 'antd'
import { useMutation } from '@apollo/client'

import { UPDATE_SITE } from '@cms/events/graphql/mutations/site'

import { usePageBuilderContext } from '@cms/events/pages/Config/Checkout/context/context'
import { Navigation, Wrapper, LinkStyled, HrefStyled } from './styles'

const Nav = () => {
    const { eventId } = useParams()
    const { pathname } = useLocation()
    const { customer } = useSelector((state) => state)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState('home')
    const { config, site, getSession } = usePageBuilderContext()
    const [updateSite] = useMutation(UPDATE_SITE)
    const baseUrl = `/evento/${eventId}/config/checkout`

    const handlePublishedSite = async () => {
        const { id } = config
        setLoading(true)

        try {
            await updateSite({
                variables: {
                    id,
                    data: site,
                    session: getSession(id)
                }
            })
            message.success('Site publicado com sucesso!')
        } catch (error) {
            message.error(error.message)
        }

        setLoading(false)
    }

    return (
        <Wrapper>
            <Affix offsetTop={0}>
                <Row className="header" justify="space-between">
                    <Col>
                        <Navigation>
                            <Col>
                                <Link
                                    className={
                                        pathname === baseUrl ? 'active-nav' : ''
                                    }
                                    to={baseUrl}
                                    onClick={() => setPage('home')}
                                >
                                    Geral
                                </Link>
                                <Link
                                    className={
                                        pathname === baseUrl + '/ticket'
                                            ? 'active-nav'
                                            : ''
                                    }
                                    to={baseUrl + '/ticket'}
                                    onClick={() => setPage('tickets')}
                                >
                                    Ingressos
                                </Link>
                                <Link
                                    className={
                                        pathname ===
                                        baseUrl + '/checkoutPayment'
                                            ? 'active-nav'
                                            : ''
                                    }
                                    to={baseUrl + '/checkoutPayment'}
                                    onClick={() => setPage('checkoutPayment')}
                                >
                                    Checkout
                                </Link>

                                <Link
                                    className={
                                        pathname === baseUrl + '/seo'
                                            ? 'active-nav'
                                            : ''
                                    }
                                    to={baseUrl + '/seo'}
                                    onClick={() => setPage('seo')}
                                >
                                    Seo
                                </Link>

                                <Link
                                    className={
                                        pathname === baseUrl + '/scripts'
                                            ? 'active-nav'
                                            : ''
                                    }
                                    to={baseUrl + '/scripts'}
                                    onClick={() => setPage('scripts')}
                                >
                                    Scripts
                                </Link>
                                <Link
                                    className={
                                        pathname === baseUrl + '/config'
                                            ? 'active-nav'
                                            : ''
                                    }
                                    to={baseUrl + '/config'}
                                    onClick={() => setPage('config')}
                                >
                                    Configurações
                                </Link>
                            </Col>
                        </Navigation>
                    </Col>
                    <Col>
                        <Space>
                            <LinkStyled
                                to={`/evento/${eventId}/config/checkout/templates`}
                            >
                                Recomeçar
                            </LinkStyled>
                            <HrefStyled
                                href={`${
                                    process.env.CHECKOUT_PREVIEW_ENDPOINT
                                }?db=${customer.db}&event=${eventId}&page=${
                                    page || 'home'
                                }`}
                                target="_blank"
                            >
                                Visualizar
                            </HrefStyled>
                            <Button
                                type="primary"
                                loading={loading}
                                onClick={handlePublishedSite}
                            >
                                Publicar
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Affix>
        </Wrapper>
    )
}

export default Nav
