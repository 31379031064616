import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_TICKET_OFFER = gql`
    mutation CreateTicketOffer(
        $event: ObjectID!
        $name: String
        $slug: String
        $image: MediaInput
        $infos: JSON
        $tickets: [TicketOfferTicketInputs]
        $allowCoupon: Boolean
        $singlePurchase: Boolean
    ) {
        createTicketOffer(
            input: {
                event: $event
                name: $name
                slug: $slug
                image: $image
                infos: $infos
                tickets: $tickets
                allowCoupon: $allowCoupon
                singlePurchase: $singlePurchase
            }
        ) {
            ...TicketOfferData
        }
    }

    ${fragments.TicketOfferData}
`

export const UPDATE_TICKET_OFFER = gql`
    mutation UpdateTicketOffer(
        $id: ObjectID!
        $event: ObjectID!
        $name: String
        $slug: String
        $image: MediaInput
        $infos: JSON
        $tickets: [TicketOfferTicketInputs]
        $allowCoupon: Boolean
        $singlePurchase: Boolean
    ) {
        updateTicketOffer(
            id: $id
            input: {
                event: $event
                name: $name
                slug: $slug
                image: $image
                infos: $infos
                tickets: $tickets
                allowCoupon: $allowCoupon
                singlePurchase: $singlePurchase
            }
        ) {
            ...TicketOfferData
        }
    }

    ${fragments.TicketOfferData}
`

export const DELETE_TICKET_OFFER = gql`
    mutation DeleteTicketOffer($id: ObjectID!) {
        deleteTicketOffer(id: $id) {
            id
            success
        }
    }
`
