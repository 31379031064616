import { gql } from '@apollo/client'
import { WatchPlanData } from '../fragments'

export const CREATE_WATCH_PLAN = gql`
    mutation CreateWatchPlan(
        $name: String
        $value: Int!
        $recurrency: WatchRecurrencyType!
        $products: [ObjectID!]!
    ) {
        createWatchPlan(
            input: {
                name: $name
                value: $value
                recurrency: $recurrency
                products: $products
            }
        ) {
            ...WatchPlanData
        }
    }
    ${WatchPlanData}
`

export const UPDATE_WATCH_PLAN = gql`
    mutation UpdateWatchPlan(
        $id: ObjectID!
        $name: String
        $value: Int!
        $recurrency: WatchRecurrencyType!
        $products: [ObjectID!]!
    ) {
        updateWatchPlan(
            id: $id
            input: {
                name: $name
                value: $value
                recurrency: $recurrency
                products: $products
            }
        ) {
            ...WatchPlanData
        }
    }
    ${WatchPlanData}
`

export const DELETE_WATCH_PLAN = gql`
    mutation DeleteWatchPlan($id: ObjectID!) {
        deleteWatchPlan(id: $id) {
            id
            success
        }
    }
`

