import dayjs from 'dayjs'

export const formatMoneyReal = (value) => {
    return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value || 0)
}

export const shouldFilterByHour = (dateRange) => {
    if (!dateRange.startDate || !dateRange.endDate) {
        return false
    }

    const startDate = dayjs(dateRange.startDate)
    const endDate = dayjs(dateRange.endDate)

    return endDate.diff(startDate, 'day') === 1
}

export const formatMoneyRealShorty = (value) => {
    if (value >= 1e9) return `R$${(value / 1e9).toFixed(1)}B` // Bilhões
    if (value >= 1e6) return `R$${(value / 1e6).toFixed(1)}M` // Milhões
    if (value >= 1e3) return `R$${(value / 1e3).toFixed(1)}K` // Milhares
    return formatMoneyReal(value)
}
