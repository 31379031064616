import {
    COMPONENTS,
    TRoute
} from '@cms/core/components/ThemeBuilder/context/types'

export const BASE_URL = `/evento/:eventId/mobile/config`

export const ROUTES: TRoute[] = [
    {
        path: `others`,
        name: COMPONENTS.MobileConfig,
        exact: true,
        home: true,
        title: 'Outras configurações'
    }
]

