import React, { useState, useEffect, useRef } from 'react'
import { Col, Row, Switch, Typography, Input, Divider } from 'antd'
import { setLightness } from 'polished'
import { throttle } from 'lodash'

import { Wrapper } from './styles'
import PopoverColor from './popoverColor'

const { Text } = Typography

export function hexColorMask(v: string): string {
    v = v.replace(/[^0-9a-fA-F]/g, '')
    v = v.substring(0, 6)
    return '#' + v
}

function validateHexColor(color: string): boolean {
    return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color)
}

interface IColorsProps {
    value?: string
    onChange?: (value: string) => void
    formItem?: boolean
    gradient?: boolean
}

const Colors = (props: IColorsProps) => {
    const { value = '', onChange, formItem = false, gradient = true } = props
    const [checked, setChecked] = useState(false)
    const [color, setColor] = useState(null)
    const [color2, setColor2] = useState(null)

    const debounceChange = useRef(throttle(onChange, 500)).current

    const handleChecked = (e: boolean) => {
        setChecked(e)

        if (e) {
            const _color = setLightness(0.5, value)
            setColor2(_color)
            onChange(color + ';' + _color)
        } else {
            setColor2(null)
            onChange(color)
        }
    }

    const handleColor1 = (e: string) => {
        let _value = e

        if (color2) {
            _value += ';' + color2
        }

        setColor(e)
        debounceChange(_value)
    }

    const handleColor2 = (e: string) => {
        const _value = color + ';' + e

        setColor2(e)
        debounceChange(_value)
    }

    useEffect(() => {
        if (value) {
            const [_color, _color2] = value.split(';')
            if (_color2) {
                setColor2(_color2)
                setChecked(true)
            }
            setColor(_color)
        }
    }, [value])

    return (
        <Wrapper formItem={formItem} className="colors-picker">
            {gradient ? (
                <Row justify="end">
                    <Col>
                        <span style={{ marginRight: '10px' }}>Degrade</span>
                        <Switch onChange={handleChecked} checked={checked} />
                    </Col>
                </Row>
            ) : (
                <br />
            )}

            <Row className="colors-group" gutter={24}>
                <Col span={8}>
                    <Text>Cor</Text>
                    <Input
                        value={color}
                        onChange={(e) => {
                            const { value } = e.target
                            const maskedValue = hexColorMask(value)
                            handleColor1(maskedValue)
                        }}
                        status={validateHexColor(color) ? '' : 'error'}
                        maxLength={7}
                    />
                </Col>
                <Col span={16}>
                    <PopoverColor onChange={handleColor1} color={color} />
                </Col>
            </Row>
            {checked ? (
                <>
                    <Divider />
                    <Row className="colors-group" gutter={24}>
                        <Col span={8}>
                            <Text>Cor 2</Text>
                            <Input
                                value={color2}
                                onChange={(e) => {
                                    const { value } = e.target
                                    const maskedValue = hexColorMask(value)
                                    handleColor2(maskedValue)
                                }}
                                status={validateHexColor(color2) ? '' : 'error'}
                                maxLength={7}
                            />
                        </Col>
                        <Col span={16}>
                            <PopoverColor
                                onChange={handleColor2}
                                color={color2}
                            />
                        </Col>
                    </Row>
                </>
            ) : null}
        </Wrapper>
    )
}

export default Colors
