import React from 'react'

import { useQuery } from '@apollo/client'

import { useCreation } from '@umijs/hooks'

import { Space } from 'antd'
import dayjs from 'dayjs'

import {
    CreateModalRoutes,
    CrudColumns,
    CrudList
} from '@cms/core/components/Crud'
import { GET_WATCH_USERS_LIST_ORDERABLE } from '@cms/watch/graphql/queries/watchUsers'
import { ButtonLink } from '@cms/events/components/Buttons'

import WatchUserModal from './WatchUsersModal'
import WatchUserProductsModal from './WatchUserProductsModal'

const path = '/usuarios'

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name'],
        sorter: 'name',
        render: (value, obj) => {
            return <CrudColumns title={obj.node.name} onlyText />
        }
    },
    {
        title: 'E-mail',
        dataIndex: ['node', 'e-mail'],
        render: (_value, obj) => {
            return <CrudColumns onlyText text={[obj.node.email]} />
        }
    },
    {
        title: 'Plano',
        dataIndex: ['node', 'watchPlan', 'name'],
        render: (_value, obj) => {
            return <CrudColumns onlyText text={[obj.node.watchPlan?.name]} />
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const WatchUsers = () => {
    const query = useQuery(GET_WATCH_USERS_LIST_ORDERABLE, {
        variables: { first: 25, orderBy: { createdAt: 'DESC' } }
    })

    if (query.error) return <div>Error</div>

    const filterFields = [
        { name: 'name', label: 'Nome', queryType: 'Filter' },
        { name: 'email', label: 'E-mail', queryType: 'Filter' }
    ]

    const ModalRoutes = useCreation(() => {
        const Routers = CreateModalRoutes(WatchUserModal, path)

        return () => (
            <>
                <Routers />
                <Routers.Custom
                    path={`${path}/:userId/produtos`}
                    render={() => <WatchUserProductsModal path={path} />}
                />
            </>
        )
    }, [])

    const actionsExtra = {
        dataIndex: '',
        key: 'x',
        fixed: 'right',
        align: 'right',
        render: (_, record) => {
            const id = record?.node?.id

            return (
                <Space>
                    <ButtonLink
                        id={id}
                        path={`produtos`}
                        key={id}
                        text={'Produtos'}
                    />
                </Space>
            )
        }
    }

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Usuários',
                    subTitle: 'Adicione e gerencie usuários',
                    buttons: [
                        {
                            children: 'Novo Usuário',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'users'}
                hideAction="delete"
                filterFields={filterFields}
                actionsExtra={actionsExtra}
                showHeader
                {...query}
            />
        </>
    )
}

export default WatchUsers

