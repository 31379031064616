import { WarningOutlined } from '@ant-design/icons'
import { Modal, Space } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const PresenceModal = ({ presence, onContinue, loading }) => {
    const history = useHistory()

    return (
        <Modal
            title={
                <Space size={3}>
                    <WarningOutlined
                        style={{
                            color: '#faad14'
                        }}
                    />{' '}
                    <span>Atenção</span>
                </Space>
            }
            open={true}
            onOk={onContinue}
            okButtonProps={{
                loading
            }}
            onCancel={() => history.push('/')}
            okText="Continuar"
            cancelText="Voltar"
        >
            <p>
                {presence?.user?.name} está editando este site. <br />
                Se você continuar, a sessão do outro usuário será finalizada.
            </p>
        </Modal>
    )
}
